import React, { useEffect, useRef, useState } from "react";
import axios from "axios";
import { Link, useParams } from "react-router-dom";
import FooterPage from "../../Layouts/FooterPage";
import { Dealer } from "../../Pages/Dealer";

export default function Categories() {
  let { names } = useParams();

  const [mctg, setMctg] = useState([]);
  const [sctg, setSctg] = useState([]);
  const [schildctg, setSchildctg] = useState([]);
  const [list, setList] = useState([]);

  document.title = `Categories | ${names} - Imon Technologies Pvt Ltd`;

  useEffect(() => {
    allCtg();
    allSubCtg();
    allSubChildCtg();
    ProfileList();
  }, []);

  const ref = useRef(null);

  const handleClick = () => {
    ref.current?.scrollIntoView({ behavior: "smooth" });
  };

  const allCtg = () => {
    axios
      .get("https://imontechnologies.in/apps/MainCategory/all.php")
      .then((res) => {
        // console.log(res.data.mctglist.mctgdata);
        setMctg(res.data.mctglist.mctgdata);
      })
      .catch((err) => console.log(err));
  };

  const allSubCtg = () => {
    axios
      .get("https://imontechnologies.in/apps/SubCategory/all.php")
      .then((res) => {
        // console.log(res.data.sctglist.sctgdata);
        setSctg(res.data.sctglist.sctgdata);
      })
      .catch((err) => console.log(err));
  };

  const allSubChildCtg = () => {
    axios
      .get(`https://imontechnologies.in/apps/SubChildCategory/all.php`)
      .then((res) => {
        // console.log(res.data.scctglist.scctgdata);
        setSchildctg(res.data.scctglist.scctgdata);
      })
      .catch((err) => console.log(err));
  };

  const ProfileList = () => {
    axios
      .get(`https://imontechnologies.in/apps/Profile/all.php`)
      .then((res) => {
        // console.log(res.data.profilelist.profiledata);
        setList(res.data.profilelist.profiledata[0]);
      });
  };
  function openNav() {
    document.getElementById("mySidenav").style.width = "250px";
  }

  function closeNav() {
    document.getElementById("mySidenav").style.width = "0";
  }
  return (
    <div className="boxed_wrapper">
      <header className="main-header style-one">
        <div className="page-header-mobile-info">
          <div className="page-header-mobile-info-content">
            <div className="header-top">
              <div className="auto-container">
                <div className="top-inner clearfix">
                  <ul className="info clearfix pull-left">
                    <li>
                      <i className="icon-hour" />
                      {list.hrs}
                    </li>
                    <li>
                      <i className="icon-placeholder" />
                      {list.location}
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="header-upper">
              <div className="auto-container">
                <div className="upper-inner clearfix">
                  <div className="logo-box pull-left">
                    <figure className="logo">
                      <a href="/">
                        <img src="/assets/images/imon-logo.png" alt="logo" />
                      </a>
                    </figure>
                  </div>
                  <ul className="info-list clearfix pull-right">
                    <li>
                      <i className="icon-email" />
                      <p>Mail Us On</p>
                      <h5>
                        <a href={`mailto:${list.email1}`}>{list.email1}</a>
                      </h5>
                    </li>
                    <li>
                      <i className="icon-smartphone" />
                      <p>Make a Call</p>
                      <h5>
                        <a href={`tel:${list.num1}`}>+91{list.num1}</a>
                      </h5>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="header-top auto-hidden">
          <div className="auto-container">
            <div className="top-inner clearfix">
              <ul className="info clearfix pull-left">
                <li>
                  <i className="icon-hour" />
                  {list.hrs}
                </li>
                <li>
                  <i className="icon-placeholder" />
                  {list.location}
                </li>
              </ul>
            </div>
          </div>
        </div>

        <div className="header-upper auto-hidden">
          <div className="auto-container">
            <div className="upper-inner clearfix">
              <div className="logo-box pull-left">
                <figure className="logo">
                  <a href="/">
                    <img
                      src="/assets/images/imon-logo.png"
                      style={{ width: 120 }}
                      alt="logo"
                    />
                  </a>
                </figure>
              </div>
              <ul className="info-list clearfix pull-right">
                <li>
                  <i className="icon-email" />
                  <p>Mail Us On</p>
                  <h5>
                    <a href={`mailto:${list.email1}`}>{list.email1}</a>
                  </h5>
                </li>
                <li>
                  <i className="icon-smartphone" />
                  <p>Make a Call</p>
                  <h5>
                    <a href={`tel:${list.num1}`}>+91{list.num1}</a>
                  </h5>
                </li>
                <li className="btn-box">
                  <button className="theme-btn style-one" onClick={handleClick}>
                    Dealers Inquiry
                  </button>
                </li>
              </ul>
            </div>
          </div>
        </div>

        <div className="header-lower">
          <div className="auto-container">
            <div className="outer-box clearfix">
              <div className="menu-area clearfix pull-left">
                <div className="mobile-nav-toggler menu-left-content clearfix">
                  <div className="form-group" style={{ marginBottom: "0rem" }}>
                    <div
                      className="row justify-content-md-start"
                      style={{ display: "inline-flex" }}
                    >
                      <div className="col mt-3" onClick={openNav}>
                        <i className="icon-bar" />
                        <i className="icon-bar" />
                        <i className="icon-bar" />
                      </div>

                      <img
                        src="/assets/images/imon-logo.png"
                        width="100px"
                        className="p-3"
                        alt="logo"
                        title
                      />
                    </div>
                  </div>

                  <div id="mySidenav" class="sidenav">
                    <a
                      href="javascript:void(0)"
                      class="closebtn "
                      onClick={closeNav}
                    >
                      &times;
                    </a>
                    <img
                      src="/assets/images/imon-logo.png"
                      width="150px"
                      className="pl-3"
                      alt="logo"
                      title
                    />

                    <ul className="navigation clearfix">
                      <li className="">
                        <a href="/" className="nav-item nav-link">
                          Home
                        </a>
                      </li>
                      <li className=''>
                        <a href="/about-us">About Us</a>
                      </li>
                      {mctg.map((list) => (
                        <li className="dropdown" key={list.main_category_id}>
                          <Link to={`/category/${list.main_category_name}`}>
                            {list.main_category_name}
                          </Link>
                        </li>
                      ))}
                      <li>
                        <a href="/contact-us">Contact Us</a>
                      </li>
                      <li className>
                        <a href="/more-details">More</a>
                      </li>
                    </ul>
                  </div>
                </div>

                <nav className="main-menu navbar-expand-md navbar-light">
                  <div
                    className="collapse navbar-collapse show clearfix"
                    id="navbarSupportedContent"
                  >
                    <ul className="navigation clearfix navbar-nav">
                      <li className="">
                        <a href="/">Home</a>
                      </li>
                      <li className=''>
                        <a href="/about-us">About Us</a>
                      </li>
                      <li className="dropdown current">
                        <a href="#">Products</a>
                        <ul>
                          {mctg.map((list) => (
                            <li
                              className="dropdown"
                              key={list.main_category_id}
                            >
                              <Link to={`/category/${list.main_category_name}`}>
                                {list.main_category_name}
                              </Link>
                              <ul>
                                {sctg
                                  .filter(
                                    (f) =>
                                      f.main_category_id ===
                                      list.main_category_id
                                  )
                                  .map((s) => (
                                    <li>
                                      <Link
                                        to={`/category/${list.main_category_name}/${s.sub_category_name}`}
                                      >
                                        {s.sub_category_name}
                                      </Link>
                                    </li>
                                  ))}
                              </ul>
                            </li>
                          ))}
                        </ul>
                      </li>
                      <li>
                        <a href="/contact-us">Contact Us</a>
                      </li>
                      <li>
                        <a href="/more-details">More</a>
                      </li>
                    </ul>
                  </div>
                </nav>
              </div>
              <div className="menu-right-content clearfix pull-right">
                <ul className="social-style-one clearfix float-right d-lg-block ">
                  <li>
                    <a href={`${list.facebook}`} target="_blank">
                      <i className="fab fa-facebook-f" />
                    </a>
                  </li>
                  <li>
                    <a href={`${list.instagram}`} target="_blank">
                      <i className="fab fa-instagram" />
                    </a>
                  </li>
                  <li>
                    <a href={`${list.linkedin}`} target="_blank">
                      <i className="fab fa-linkedin-in" />
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>


        <div className="sticky-header">
          <div className="auto-container">
            <div className="outer-box clearfix">
              <div className="menu-area pull-left">
                <nav className="main-menu navbar-expand-md navbar-light">
                  <div
                    className="collapse navbar-collapse show clearfix"
                    id="navbarSupportedContent"
                  >
                    <ul className="navigation clearfix">
                      <li className="">
                        <a href="/">Home</a>
                      </li>
                      <li className>
                        <a href="/about-us">About Us</a>
                      </li>
                      <li className="dropdown current">
                        <a href="/">Products</a>
                        <ul>
                          {mctg.map((list) => (
                            <li
                              className="dropdown"
                              key={list.main_category_id}
                            >
                              <Link to={`/category/${list.main_category_name}`}>
                                {list.main_category_name}
                              </Link>
                              <ul>
                                {sctg
                                  .filter(
                                    (f) =>
                                      f.main_category_id ===
                                      list.main_category_id
                                  )
                                  .map((s) => (
                                    <li>
                                      <Link
                                        to={`/category/${list.main_category_name}/${s.sub_category_name}`}
                                      >
                                        {s.sub_category_name}
                                      </Link>
                                    </li>
                                  ))}
                              </ul>
                            </li>
                          ))}
                        </ul>
                      </li>
                      <li>
                        <a href="/contact-us">Contact Us</a>
                      </li>
                      <li>
                        <a href="/more-details">More</a>
                      </li>
                    </ul>
                  </div>
                </nav>
              </div>
              <div className="menu-right-content clearfix pull-right">
                <ul className="social-style-one clearfix">
                  <li>
                    <a href={`${list.facebook}`} target="_blank">
                      <i className="fab fa-facebook-f" />
                    </a>
                  </li>
                  <li>
                    <a href={`${list.instagram}`} target="_blank">
                      <i className="fab fa-instagram" />
                    </a>
                  </li>
                  <li>
                    <a href={`${list.linkedin}`} target="_blank">
                      <i className="fab fa-linkedin-in" />
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </header>

      <div className="mobile-menu">
        <div className="menu-backdrop" />
        <div className="close-btn">
          <i className="fas fa-times" />
        </div>
        <nav className="menu-box">
          <div className="nav-logo">
            <a href="/">
              <img
                src="/assets/images/imon-logo.png"
                width="150px"
                alt="logo"
                title
              />
            </a>
          </div>

          <div className="contact-info">
            <h4>Contact Info</h4>
            <ul>
              <li>{list.location}</li>
              <li>
                <a href={`tel:+${list.num1}`}>+91{list.num1}</a>
              </li>
              <li>
                <a href={`mailto:${list.email1}`}>{list.email1}</a>
              </li>
            </ul>
          </div>
          <div className="social-links">
            <ul className="clearfix">
              <li>
                <a href={`${list.facebook}`}>
                  <span className="fab fa-facebook-square" />
                </a>
              </li>
              <li>
                <a href={`${list.instagram}`}>
                  <span className="fab fa-instagram" />
                </a>
              </li>
              <li>
                <a href={`${list.linkedin}`}>
                  <i className="fab fa-linkedin-in" />
                </a>
              </li>
            </ul>
          </div>
        </nav>
      </div>

      {mctg
        .filter((d) => d.main_category_name === names)
        .map((list) => (
          <section
            key={list.main_category_id}
            className="page-title"
            style={{
              backgroundImage: `url(/mainctgImgs/${list.main_category_img})`,
            }}
          >
            <div className="auto-container">
              <div className="content-box">
                <div className="title">
                  <h1>{list.main_category_name}</h1>
                </div>
                <ul className="bread-crumb clearfix text-left">
                  <li>
                    <a href="/">Home</a>
                  </li>
                  <li>{list.main_category_name}</li>
                </ul>
              </div>
            </div>
          </section>
        ))}

      <section className="project-page-section three-column">
        <div className="auto-container">
          <div className="sortable-masonry">
            <div className="filters centred">
              <ul
                className="nav nav-pills justify-content-center filter-tabs filter-btns clearfix"
                id="pills-tab"
                role="tablist"
              >
                {mctg
                  .filter((d) => d.main_category_name === names)
                  .map((mlist) => (
                    <>
                      <li className="nav-item filter" role="presentation">
                        <button
                          className="nav-link filter active"
                          id="pills-profile-tab"
                          data-toggle="pill"
                          data-target={`#id`}
                          type="button"
                          role="tab"
                          aria-controls="pills-profile"
                          aria-selected="false"
                        >
                          View All
                        </button>
                      </li>
                      {sctg
                        .filter(
                          (d) => d.main_category_id === mlist.main_category_id
                        )
                        .map((slist) => (
                          <li className="nav-item" role="presentation">
                            <button
                              className="nav-link filter"
                              id="pills-profile-tab"
                              data-toggle="pill"
                              data-target={`#id${slist.sub_category_id}`}
                              type="button"
                              role="tab"
                              aria-controls="pills-profile"
                              aria-selected="false"
                            >
                              {slist.sub_category_name}
                            </button>
                          </li>
                        ))}
                    </>
                  ))}
              </ul>
            </div>
            <div className="tab-content" id="pills-tabContent">
              {mctg
                .filter((d) => d.main_category_name === names)
                .map((mlist) => (
                  <>
                    <div
                      className="tab-pane fade show active"
                      id={`id`}
                      role="tabpanel"
                      aria-labelledby="pills-profile-tab"
                    >
                      <div className="items-container row clearfix">
                        {mctg
                          .filter((d) => d.main_category_name === names)
                          .map((mlist) => (
                            <>
                              {sctg
                                .filter(
                                  (d) =>
                                    d.main_category_id ===
                                    mlist.main_category_id
                                )
                                .map((slist) => (
                                  <>
                                    {schildctg
                                      .filter(
                                        (d) =>
                                          d.main_category_id ===
                                            mlist.main_category_id &&
                                          d.sub_category_id ===
                                            slist.sub_category_id
                                      )
                                      .map((sclist) => (
                                        <>
                                          <div className="col-lg-3 col-md-6 col-sm-12 masonry-item small-column">
                                            <div className="shop-block-one">
                                              <div
                                                className="inner-box"
                                                style={{
                                                  boxShadow:
                                                    "0px 10px 20px 0px rgb(0 0 0 / 12%)",
                                                  height: "430px",
                                                }}
                                              >
                                                <figure className="image-box">
                                                  <a
                                                    href={`/category/${mlist.main_category_name}/${slist.sub_category_name}/product/${sclist.sub_child_category_name}`}
                                                  >
                                                    <img
                                                      src={`/subctgImgs/${sclist.sub_child_category_img}`}
                                                      alt={
                                                        sclist.sub_child_category_img
                                                      }
                                                    />
                                                  </a>
                                                </figure>
                                                <div className="content-box">
                                                  <div className="lower-content">
                                                    <h6>
                                                      <Link
                                                        to={`/category/${mlist.main_category_name}/${slist.sub_category_name}/product/${sclist.sub_child_category_name}`}
                                                      >
                                                        {
                                                          sclist.sub_child_category_name
                                                        }
                                                      </Link>
                                                    </h6>

                                                    <span
                                                      className="price"
                                                      ref={ref}
                                                    >
                                                      {
                                                        sclist.sub_child_category_des
                                                      }
                                                    </span>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </>
                                      ))}
                                  </>
                                ))}
                            </>
                          ))}
                      </div>
                    </div>

                    {sctg
                      .filter(
                        (d) => d.main_category_id === mlist.main_category_id
                      )
                      .map((slist) => (
                        <div
                          className="tab-pane fade"
                          id={`id${slist.sub_category_id}`}
                          role="tabpanel"
                          aria-labelledby="pills-profile-tab"
                        >
                          <>
                            <div class="row">
                              {schildctg
                                .filter(
                                  (d) =>
                                    d.main_category_id ===
                                      mlist.main_category_id &&
                                    d.sub_category_id === slist.sub_category_id
                                )
                                .map((sclist) => (
                                  <>
                                    <div className="col-lg-3 col-md-6 col-sm-12 masonry-item small-column">
                                      <div className="shop-block-one">
                                        <div
                                          className="inner-box"
                                          style={{
                                            boxShadow:
                                              "0px 10px 20px 0px rgb(0 0 0 / 12%)",
                                            height: "430px",
                                          }}
                                        >
                                          <figure className="image-box">
                                            <a
                                              href={`/category/${mlist.main_category_name}/${slist.sub_category_name}/product/${sclist.sub_child_category_name}`}
                                            >
                                              <img
                                                src={`/subctgImgs/${sclist.sub_child_category_img}`}
                                                alt={
                                                  sclist.sub_child_category_img
                                                }
                                              />
                                            </a>
                                          </figure>
                                          <div className="content-box">
                                            <div className="lower-content">
                                              <h6>
                                                <Link
                                                  to={`/category/${mlist.main_category_name}/${slist.sub_category_name}/product/${sclist.sub_child_category_name}`}
                                                >
                                                  {
                                                    sclist.sub_child_category_name
                                                  }
                                                </Link>
                                              </h6>

                                              <span className="price" ref={ref}>
                                                {sclist.sub_child_category_des}
                                              </span>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </>
                                ))}
                            </div>
                          </>
                        </div>
                      ))}
                  </>
                ))}
            </div>
          </div>
        </div>
      </section>

      <Dealer />

      <FooterPage />
    </div>
  );
}
