import React, { useEffect, useRef, useState } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import FooterPage from "../Layouts/FooterPage";
import { useFormik } from "formik";
import { Dealer } from "./Dealer";

export default function HomePage() {
  const [mctg, setMctg] = useState([]);
  const [sctg, setSctg] = useState([]);
  const [plist, setPlist] = useState([]);

  useEffect(() => {
    allCtg();
    allSubCtg();
    ProfileList();
  }, []);

  const ref = useRef(null);

  const handleClick = () => {
    ref.current?.scrollIntoView({ behavior: "smooth" });
  };

  const formik = useFormik({
    initialValues: {
      name: "",
      company: "",
      phone: "",
      city: "",
      productid: "",
    },
    onSubmit: (values) => {
      axios
        .post(`https://imontechnologies.in/apps/ClientInquiry/add.php`, list)
        .then((res) => {});
    },
  });

  const list = {
    name: formik.values.name,
    company: formik.values.company,
    phone: formik.values.phone,
    city: formik.values.city,
    productid: formik.values.productid,
  };

  const allCtg = () => {
    axios
      .get("https://imontechnologies.in/apps/MainCategory/all.php")
      .then((res) => {
        // console.log(res.data.mctglist.mctgdata);
        setMctg(res.data.mctglist.mctgdata);
      })
      .catch((err) => console.log(err));
  };
  const allSubCtg = () => {
    axios
      .get("https://imontechnologies.in/apps/SubCategory/all.php")
      .then((res) => {
        // console.log(res.data.sctglist.sctgdata);
        setSctg(res.data.sctglist.sctgdata);
      })
      .catch((err) => console.log(err));
  };
  const ProfileList = () => {
    axios
      .get(`https://imontechnologies.in/apps/Profile/all.php`)
      .then((res) => {
        // console.log(res.data.profilelist.profiledata);
        setPlist(res.data.profilelist.profiledata[0]);
      });
  };
  function openNav() {
    document.getElementById("mySidenav").style.width = "250px";
  }

  function closeNav() {
    document.getElementById("mySidenav").style.width = "0";
  }
  return (
    <div className="boxed_wrapper">
      <header className="main-header style-one">
        <div className="page-header-mobile-info">
          <div className="page-header-mobile-info-content">
            <div className="header-top">
              <div className="auto-container">
                <div className="top-inner clearfix">
                  <ul className="info clearfix pull-left">
                    <li>
                      <i className="icon-hour" />
                      {plist.hrs}
                    </li>
                    <li>
                      <i className="icon-placeholder" />
                      {plist.location}
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="header-upper">
              <div className="auto-container">
                <div className="upper-inner clearfix">
                  <div className="logo-box pull-left">
                    <figure className="logo">
                      <a href="/">
                        <img src="/assets/images/imon-logo.png" alt="logo" />
                      </a>
                    </figure>
                  </div>
                  <ul className="info-list clearfix pull-right">
                    <li>
                      <i className="icon-email" />
                      <p>Mail Us On</p>
                      <h5>
                        <a href={`${plist.email1}`}>{plist.email1}</a>
                      </h5>
                    </li>
                    <li>
                      <i className="icon-smartphone" />
                      <p>Make a Call</p>
                      <h5>
                        <a href={`tel:${plist.num1}`}>+91{plist.num1}</a>
                      </h5>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="header-top auto-hidden">
          <div className="auto-container">
            <div className="top-inner clearfix">
              <ul className="info clearfix pull-left">
                <li>
                  <i className="icon-hour" />
                  {plist.hrs}
                </li>
                <li>
                  <i className="icon-placeholder" />
                  {plist.location}
                </li>
              </ul>
            </div>
          </div>
        </div>

        <div className="header-upper auto-hidden">
          <div className="auto-container">
            <div className="upper-inner clearfix">
              <div className="logo-box pull-left">
                <figure className="logo">
                  <a href="/">
                    <img
                      src="/assets/images/imon-logo.png"
                      style={{ width: 120 }}
                      alt="logo"
                    />
                  </a>
                </figure>
              </div>
              <ul className="info-list clearfix pull-right">
                <li>
                  <i className="icon-email" />
                  <p>Mail Us On</p>
                  <h5>
                    <a href={`mailto:${plist.email1}`}>{plist.email1}</a>
                  </h5>
                </li>
                <li>
                  <i className="icon-smartphone" />
                  <p>Make a Call</p>
                  <h5>
                    <a href={`tel:${plist.num1}`}>+91{plist.num1}</a>
                  </h5>
                </li>
                <li className="btn-box">
                  <button className="theme-btn style-one" onClick={handleClick}>
                    Dealers Inquiry
                  </button>
                </li>
              </ul>
            </div>
          </div>
        </div>

        <div className="header-lower">
          <div className="auto-container">
            <div className="outer-box clearfix">
              <div className="menu-area clearfix pull-left">
                <div className="mobile-nav-toggler menu-left-content clearfix">
                  <div className="form-group" style={{ marginBottom: "0rem" }}>
                    <div
                      className="row justify-content-md-start"
                      style={{ display: "inline-flex" }}
                    >
                      <div className="col mt-3" onClick={openNav}>
                        <i className="icon-bar" />
                        <i className="icon-bar" />
                        <i className="icon-bar" />
                      </div>

                      <img
                        src="assets/images/imon-logo.png"
                        width="100px"
                        className="p-3"
                        alt="logo"
                        title
                      />
                    </div>
                  </div>

                  <div id="mySidenav" class="sidenav">
                    <a
                      href="javascript:void(0)"
                      class="closebtn "
                      onClick={closeNav}
                    >
                      &times;
                    </a>
                    <img
                      src="assets/images/imon-logo.png"
                      width="150px"
                      className="pl-3"
                      alt="logo"
                      title
                    />

                    <ul className="navigation clearfix">
                      <li className="current">
                        <a href="/" className="nav-item nav-link">
                          Home
                        </a>
                      </li>
                      <li>
                        <a href="/about-us">About Us</a>
                      </li>
                      {mctg.map((list) => (
                        <li className="dropdown" key={list.main_category_id}>
                          <Link to={`/category/${list.main_category_name}`}>
                            {list.main_category_name}
                          </Link>
                        </li>
                      ))}
                      <li>
                        <a href="/contact-us">Contact Us</a>
                      </li>
                      <li>
                        <a href="/more-details">More</a>
                      </li>
                    </ul>
                  </div>
                </div>

                <nav className="main-menu navbar-expand-md navbar-light">
                  <div
                    className="collapse navbar-collapse show clearfix"
                    id="navbarSupportedContent"
                  >
                    <ul className="navigation clearfix navbar-nav">
                      <li className="current">
                        <a href="/">Home</a>
                      </li>
                      <li className>
                        <a href="/about-us">About Us</a>
                      </li>
                      <li className="dropdown">
                        <a href="#">Products</a>
                        <ul>
                          {mctg.map((list) => (
                            <li
                              className="dropdown"
                              key={list.main_category_id}
                            >
                              <Link to={`/category/${list.main_category_name}`}>
                                {list.main_category_name}
                              </Link>
                              <ul>
                                {sctg
                                  .filter(
                                    (f) =>
                                      f.main_category_id ===
                                      list.main_category_id
                                  )
                                  .map((s) => (
                                    <li>
                                      <Link
                                        to={`/category/${list.main_category_name}/${s.sub_category_name}`}
                                      >
                                        {s.sub_category_name}
                                      </Link>
                                    </li>
                                  ))}
                              </ul>
                            </li>
                          ))}
                        </ul>
                      </li>
                      <li>
                        <a href="/contact-us">Contact Us</a>
                      </li>
                      <li>
                        <a href="/more-details">More</a>
                      </li>
                    </ul>
                  </div>
                </nav>
              </div>
              <div className="menu-right-content clearfix pull-right">
                <ul className="social-style-one clearfix float-right d-lg-block ">
                  <li>
                    <a href={`${plist.facebook}`} target="_blank">
                      <i className="fab fa-facebook-f" />
                    </a>
                  </li>
                  <li>
                    <a href={`${plist.instagram}`} target="_blank">
                      <i className="fab fa-instagram" />
                    </a>
                  </li>
                  <li>
                    <a href={`${plist.linkedin}`} target="_blank">
                      <i className="fab fa-linkedin-in" />
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>

        <div className="sticky-header ">
          <div className="auto-container">
            <div className="outer-box clearfix">
              <div className="menu-area pull-left">
                <nav className="main-menu navbar-expand-md navbar-light">
                  <div className="collapse navbar-collapse show clearfix">
                    <ul className="navigation clearfix">
                      <li className="current">
                        <a href="/">Home</a>
                      </li>
                      <li className>
                        <a href="/about-us">About Us</a>
                      </li>
                      <li className="dropdown">
                        <a href="#">Products</a>
                        <ul>
                          {mctg.map((list) => (
                            <li
                              className="dropdown"
                              key={list.main_category_id}
                            >
                              <Link to={`/category/${list.main_category_name}`}>
                                {list.main_category_name}
                              </Link>
                              <ul>
                                {sctg
                                  .filter(
                                    (f) =>
                                      f.main_category_id ===
                                      list.main_category_id
                                  )
                                  .map((s) => (
                                    <li>
                                      <Link
                                        to={`/category/${list.main_category_name}/${s.sub_category_name}`}
                                      >
                                        {s.sub_category_name}
                                      </Link>
                                    </li>
                                  ))}
                              </ul>
                            </li>
                          ))}
                        </ul>
                      </li>

                      <li>
                        <a href="/contact-us">Contact Us</a>
                      </li>
                      <li>
                        <a href="/more-details">More</a>
                      </li>
                    </ul>
                  </div>
                </nav>
              </div>
              <div className="menu-right-content clearfix pull-right">
                <ul className="social-style-one clearfix">
                  <li>
                    <a href={`${plist.facebook}`} target="_blank">
                      <i className="fab fa-facebook-f" />
                    </a>
                  </li>
                  <li>
                    <a href={`${plist.instagram}`} target="_blank">
                      <i className="fab fa-instagram" />
                    </a>
                  </li>
                  <li>
                    <a href={`${plist.linkedin}`} target="_blank">
                      <i className="fab fa-linkedin-in" />
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </header>

      <div className="mobile-menu" id="mobile-menu">
        <div className="menu-backdrop" />
        <div className="close-btn">
          <i className="fas fa-times" />
        </div>
        <nav className="menu-box">
          <div className="nav-logo">
            <a href="/">
              <img
                src="assets/images/imon-logo.png"
                width="150px"
                alt="logo"
                title
              />
            </a>
          </div>

          <div className="contact-info">
            <h4>Contact Info</h4>
            <ul>
              <li>{plist.location}</li>
              <li>
                <a href={`tel:+${plist.num1}`}>+91{plist.num1}</a>
              </li>
              <li>
                <a href={`mailto:${plist.email1}`}>{plist.email1}</a>
              </li>
            </ul>
          </div>
          <div className="social-links">
            <ul className="clearfix">
              <li>
                <a href={`${plist.facebook}`}>
                  <i className="fab fa-facebook-f" />
                </a>
              </li>
              <li>
                <a href={`${plist.instagram}`}>
                  <i className="fab fa-instagram" />
                </a>
              </li>
              <li>
                <a href={`${plist.linkedin}`}>
                  <i className="fab fa-linkedin-in" />
                </a>
              </li>
            </ul>
          </div>
        </nav>
      </div>

      <section className="banner-section">
        <div className="banner-carousel owl-theme owl-carousel dots-style-one">
          <div className="slide-item">
            <div
              className="image-layer"
              style={{
                backgroundImage: "url(/assets/images/banner/img_sliding.jpg)",
              }}
            />
            <div className="auto-container">
              <div className="row clearfix">
                <div className="col-xl-6 col-lg-12 col-md-12 content-column">
                  <div className="content-box">
                    <h2>
                      Wide Range of <br />
                      Motors for Brushless Sliding Gates
                    </h2>
                    <p>
                      Professional discretion security for a changing world
                      Nothing less than the best.
                    </p>
                    <div className="btn-box">
                      <a
                        href="/category/Sliding Gates"
                        className="theme-btn style-one"
                      >
                        View More
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="slide-item style-two">
            <div
              className="image-layer"
              style={{
                backgroundImage: "url(/assets/images/banner/img_1.jpg)",
              }}
            />
            <div className="auto-container">
              <div className="row clearfix">
                <div className="col-xl-6 col-lg-12 col-md-12 offset-xl-6 content-column">
                  <div className="content-box">
                    <h2 style={{ color: "white" }}>
                      Leader in protecting people and places.
                    </h2>
                    <p style={{ color: "white" }}>
                      The revolutionary digital and REVERSIBLE option for
                      commercial and industrial openings is born.
                    </p>
                    <div className="btn-box">
                      <a
                        href="/category/Swing Gates"
                        className="theme-btn style-one"
                      >
                        View More
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="slide-item">
            <div
              className="image-layer"
              style={{
                backgroundImage: "url(assets/images/banner/auto.jpg)",
              }}
            />
            <div className="auto-container">
              <div className="row clearfix">
                <div className="col-xl-7 col-lg-12 col-md-12 content-column">
                  <div className="content-box">
                    <h2>
                      The ultimate <br />
                      choice for trustable security service.
                    </h2>
                    <p>
                      To take a trivial example, which of us ever undertakes
                      laborious physical exerciseexcept to obtain some.
                    </p>
                    <div className="btn-box">
                      <a
                        href="/category/Automatic Barriers"
                        className="theme-btn style-one"
                      >
                        View More
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="about-section">
        <div className="auto-container">
          <div className="row clearfix">
            <div className="col-lg-6 col-md-12 col-sm-12 image-column">
              <div className="image_block_1">
                <div className="image-box">
                  <div className="shape-box">
                    <div
                      className="line line-1"
                      style={{
                        backgroundImage: "url(/assets/images/icons/line-1.png)",
                      }}
                    />
                    <div
                      className="line line-2"
                      style={{
                        backgroundImage: "url(/assets/images/icons/line-2.png)",
                      }}
                    />
                    <div
                      className="line line-3"
                      style={{
                        backgroundImage: "url(/assets/images/icons/line-3.png)",
                      }}
                    />
                    <div
                      className="line line-4"
                      style={{
                        backgroundImage: "url(/assets/images/icons/line-4.png)",
                      }}
                    />
                    <div className="circle" />
                  </div>
                  <figure className="image">
                    <img
                      src="/assets/images/resource/sliding.jpg"
                      alt="sliding.jpg"
                    />
                  </figure>
                  <div className="content-box">
                    <figure className="curve-text">
                      <img
                        src="/assets/images/icons/text.png"
                        alt="curve-text.png"
                      />
                    </figure>
                    <div className="text">
                      <h2>
                        27 <span>+</span>
                      </h2>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-md-12 col-sm-12 content-column">
              <div className="content_block_1">
                <div className="content-box">
                  <div className="sec-title">
                    <div className="top-title">
                      <div className="shape">
                        <div className="line line-1" />
                        <div className="line line-2" />
                        <div className="line line-3" />
                        <div className="line line-4" />
                        <div
                          className="bg-shape"
                          style={{
                            backgroundImage:
                              "url(/assets/images/icons/bg-shape.png)",
                          }}
                        />
                      </div>
                      <p>About Company</p>
                    </div>
                    <h2>Professional security services you can trust</h2>
                  </div>
                  <div className="text">
                    <h4>The professional approach to security.</h4>
                    <p>
                      Through quality management of commercial, industrial, and
                      residential properties, we aim to make people's lives
                      better. With its professional line of sliding gates, swing
                      gates, and automatic barriers, Imon produces a wide range
                      of automated solutions.
                    </p>
                    <br />
                    <p>
                      As a result of our extensive range, the simplest
                      integration, the most advanced electronic components, the
                      finest design, and the most attractive and complete
                      automation system, imon offers an unparalleled experience.
                    </p>
                  </div>

                  <div className="lower-content clearfix">
                    <h4>“Roger Technology premium dealer.”</h4>
                    <div className="btn-box">
                      <a href="/about-us" className="theme-btn style-one">
                        View More
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section
        className="chooseus-style-three"
        style={{
          backgroundImage: "url(/assets/images/background/chooseus-bg.jpg)",
        }}
      >
        <div class="auto-container">
          <div class="inner-container">
            <div class="row clearfix text-center">
              <div class="col-lg-3 col-md-6 col-sm-12 feature-block">
                <div
                  class="feature-block-one wow fadeInUp animated"
                  data-wow-delay="00ms"
                  data-wow-duration="1500ms"
                >
                  <div class="icon-box" style={{ background: "#009b00" }}>
                    <img
                      className="pb-2"
                      src="assets/images/icons/gear.png"
                      width="50px"
                    />
                  </div>
                  <div class="inner-box">
                    <p>Legacy of Industry Experience & Expertise</p>
                  </div>
                </div>
              </div>
              <div class="col-lg-3 col-md-6 col-sm-12 feature-block">
                <div
                  class="feature-block-one wow fadeInUp animated"
                  data-wow-delay="300ms"
                  data-wow-duration="1500ms"
                >
                  <div class="icon-box" style={{ background: "#009b00" }}>
                    <img
                      className="pb-2"
                      src="assets/images/icons/creative-process.png"
                      width="50px"
                    />
                  </div>
                  <div class="inner-box">
                    <p>Company with Process Driven Approach</p>
                  </div>
                </div>
              </div>
              <div class="col-lg-3 col-md-6 col-sm-12 feature-block">
                <div
                  class="feature-block-one wow fadeInUp animated"
                  data-wow-delay="600ms"
                  data-wow-duration="1500ms"
                >
                  <div class="icon-box" style={{ background: "#009b00" }}>
                    <img
                      className="pb-3"
                      src="assets/images/icons/goal.png"
                      width="50px"
                    />
                  </div>
                  <div class="inner-box">
                    <p>Core Values & Transparency As A Focus</p>
                  </div>
                </div>
              </div>
              <div class="col-lg-3 col-md-6 col-sm-12 feature-block">
                <div
                  class="feature-block-one wow fadeInUp animated"
                  data-wow-delay="600ms"
                  data-wow-duration="1500ms"
                >
                  <div class="icon-box" style={{ background: "#009b00" }}>
                    <img
                      className="pb-3"
                      src="assets/images/icons/network.png"
                      width="50px"
                    />
                  </div>
                  <div class="inner-box">
                    <p>Distinct & Innovative Brand Managed Online</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="service-section sec-pad">
        <div className="auto-container">
          <div className="sec-title centred ">
            <div className="top-title">
              <div className="shape">
                <div className="line line-1" />
                <div className="line line-2" />
                <div className="line line-3" />
                <div className="line line-4" />
                <div
                  className="bg-shape"
                  style={{
                    backgroundImage: "url(/assets/images/icons/bg-shape.png)",
                  }}
                />
              </div>
              <p>Product Overview</p>
            </div>
            <h2>
              High quality products <br />
              that we stand behind
            </h2>
          </div>
          <div className="row three-item dots-style-one">
            {mctg.map((list) => (
              <div
                className="col-lg-4 col-md-6 col-sm-12 service-block-one"
                key={list.main_category_id}
              >
                <div
                  className="inner-box"
                  style={{
                    backgroundImage: `url(/mainctgImgs/${list.main_category_img})`,
                  }}
                >
                  <div className="content-box">
                    <span className="category" />
                    <h3
                      style={{
                        color: "white",
                      

                        fontWeight: "500",
                      }}
                    >
                      {list.main_category_name}
                    </h3>
                    <div className="link">
                      <a
                        href={`/category/${list.main_category_name}`}
                        ref={ref}
                      >
                        View More
                        <i className="fas fa-angle-right" />
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>

      <Dealer />
      <FooterPage />
    </div>
  );
}
