import React, { useEffect, useRef, useState } from "react";
import axios from "axios";
import { Link, useParams } from "react-router-dom";
import FooterPage from "../../Layouts/FooterPage";
import { useFormik } from "formik";
import { Dealer } from "../../Pages/Dealer";
import { format } from "date-fns";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export default function ProductDetails() {
  let { product } = useParams();
  let { names } = useParams();
  let { snames } = useParams();
  const [mctg, setMctg] = useState([]);
  const [sctg, setSctg] = useState([]);
  const [schildctg, setSchildctg] = useState([]);
  const [products, setProducts] = useState([]);
  const [icons, setIcons] = useState([]);
  const [list, setList] = useState([]);

  document.title = `${product} | ${snames} | ${names} - Imon Technologies Pvt Ltd`;

  useEffect(() => {
    allCtg();
    allSubCtg();
    allSubChildCtg();
    allProducts();
    allIcons();
    ProfileList();
  }, []);

  const ref = useRef(null);

  const handleClick = () => {
    ref.current?.scrollIntoView({ behavior: "smooth" });
  };

  const allCtg = () => {
    axios
      .get("https://imontechnologies.in/apps/MainCategory/all.php")
      .then((res) => {
        //  console.log(res.data.mctglist.mctgdata);
        setMctg(res.data.mctglist.mctgdata);
      })
      .catch((err) => console.log(err));
  };

  const allSubCtg = () => {
    axios
      .get("https://imontechnologies.in/apps/SubCategory/all.php")
      .then((res) => {
        // console.log(res.data.sctglist.sctgdata);
        setSctg(res.data.sctglist.sctgdata);
      })
      .catch((err) => console.log(err));
  };

  const allSubChildCtg = () => {
    axios
      .get(`https://imontechnologies.in/apps/SubChildCategory/all.php`)
      .then((res) => {
        // console.log(res.data.scctglist.scctgdata);
        setSchildctg(res.data.scctglist.scctgdata);
      })
      .catch((err) => console.log(err));
  };

  const allProducts = () => {
    axios
      .get(`https://imontechnologies.in/apps/Products/all.php`)
      .then((res) => {
        //  console.log(res.data.prlist.prdata);
        setProducts(res.data.prlist.prdata);
      })
      .catch((err) => console.log(err));
  };

  const allIcons = () => {
    axios
      .get(`https://imontechnologies.in/apps/ProductIcons/all.php`)
      .then((res) => {
        // console.log(res.data.producticonlist.producticondata);
        setIcons(res.data.producticonlist.producticondata);
      })
      .catch((err) => console.log(err));
  };

  const formik = useFormik({
    initialValues: {
      name: "",
      company: "",
      city: "",
      phone: "",
      email: "",
      custtype: "",
      msg: "",
      productName: "",
      productpdf: "",
      date: format(new Date(), "dd-MM-yyyy"),
    },
    onSubmit: (values) => {
      // console.log(values);
      axios
        .post(`https://imontechnologies.in/email.php`, values)
        .then((res) => {
          console.log(res.data);

          if (res.data.success === true) {
            toast.info("Check Your Email", { autoClose: 5000 });
          } else {
            toast.error("Please fill all the required fields!", {
              autoClose: 3000,
            });
          }
        });
      formik.resetForm();
    },
  });

  const ProfileList = () => {
    axios
      .get(`https://imontechnologies.in/apps/Profile/all.php`)
      .then((res) => {
        // console.log(res.data.profilelist.profiledata);
        setList(res.data.profilelist.profiledata[0]);
      });
  };
  function openNav() {
    document.getElementById("mySidenav").style.width = "250px";
  }

  function closeNav() {
    document.getElementById("mySidenav").style.width = "0";
  }
  return (
    <div className="boxed_wrapper">
      <header className="main-header style-one">
        <div className="page-header-mobile-info">
          <div className="page-header-mobile-info-content">
            <div className="header-top">
              <div className="auto-container">
                <div className="top-inner clearfix">
                  <ul className="info clearfix pull-left">
                    <li>
                      <i className="icon-hour" />
                      {list.hrs}
                    </li>
                    <li>
                      <i className="icon-placeholder" />
                      {list.location}
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="header-upper">
              <div className="auto-container">
                <div className="upper-inner clearfix">
                  <div className="logo-box pull-left">
                    <figure className="logo">
                      <a href="/">
                        <img src="/assets/images/imon-logo.png" alt="logo" />
                      </a>
                    </figure>
                  </div>
                  <ul className="info-list clearfix pull-right">
                    <li>
                      <i className="icon-email" />
                      <p>Mail Us On</p>
                      <h5>
                        <a href={`mailto:${list.email1}`}>{list.email1}</a>
                      </h5>
                    </li>
                    <li>
                      <i className="icon-smartphone" />
                      <p>Make a Call</p>
                      <h5>
                        <a href={`tel:${list.num1}`}>+91{list.num1}</a>
                      </h5>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="header-top auto-hidden">
          <div className="auto-container">
            <div className="top-inner clearfix">
              <ul className="info clearfix pull-left">
                <li>
                  <i className="icon-hour" />
                  {list.hrs}
                </li>
                <li>
                  <i className="icon-placeholder" />
                  {list.location}
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div className="header-upper auto-hidden">
          <div className="auto-container">
            <div className="upper-inner clearfix">
              <div className="logo-box pull-left">
                <figure className="logo">
                  <a href="/">
                    <img
                      src="/assets/images/imon-logo.png"
                      style={{ width: 120 }}
                      alt="logo"
                    />
                  </a>
                </figure>
              </div>
              <ul className="info-list clearfix pull-right">
                <li>
                  <i className="icon-email" />
                  <p>Mail Us On</p>
                  <h5>
                    <a href={`mailto:${list.email1}`}>{list.email1}</a>
                  </h5>
                </li>
                <li>
                  <i className="icon-smartphone" />
                  <p>Make a Call</p>
                  <h5>
                    <a href={`tel:${list.num1}`}>+91{list.num1}</a>
                  </h5>
                </li>
                <li className="btn-box">
                  <button className="theme-btn style-one" onClick={handleClick}>
                    Dealers Inquiry
                  </button>
                </li>
              </ul>
            </div>
          </div>
        </div>

        <div className="header-lower">
          <div className="auto-container">
            <div className="outer-box clearfix">
              <div className="menu-area clearfix pull-left">
                <div className="mobile-nav-toggler menu-left-content clearfix">
                  <div className="form-group" style={{ marginBottom: "0rem" }}>
                    <div
                      className="row justify-content-md-start"
                      style={{ display: "inline-flex" }}
                    >
                      <div className="col mt-3" onClick={openNav}>
                        <i className="icon-bar" />
                        <i className="icon-bar" />
                        <i className="icon-bar" />
                      </div>

                      <img
                        src="/assets/images/imon-logo.png"
                        width="100px"
                        className="p-3"
                        alt="logo"
                        title
                      />
                    </div>
                  </div>

                  <div id="mySidenav" class="sidenav">
                    <a
                      href="javascript:void(0)"
                      class="closebtn "
                      onClick={closeNav}
                    >
                      &times;
                    </a>
                    <img
                      src="/assets/images/imon-logo.png"
                      width="150px"
                      className="pl-3"
                      alt="logo"
                      title
                    />

                    <ul className="navigation clearfix">
                      <li>
                        <a href="/" className="nav-item nav-link">
                          Home
                        </a>
                      </li>
                      <li>
                        <a href="/about-us">About Us</a>
                      </li>
                      {mctg.map((list) => (
                        <li
                          className="dropdown current"
                          key={list.main_category_id}
                        >
                          <Link to={`/category/${list.main_category_name}`}>
                            {list.main_category_name}
                          </Link>
                        </li>
                      ))}
                      <li>
                        <a href="/contact-us">Contact Us</a>
                      </li>
                      <li className>
                        <a href="/more-details">More</a>
                      </li>
                    </ul>
                  </div>
                </div>

                <nav className="main-menu navbar-expand-md navbar-light">
                  <div
                    className="collapse navbar-collapse show clearfix"
                    id="navbarSupportedContent"
                  >
                    <ul className="navigation clearfix navbar-nav">
                      <li>
                        <a href="/">Home</a>
                      </li>
                      <li>
                        <a href="/about-us">About Us</a>
                      </li>
                      <li className="dropdown current">
                        <a href="#">Products</a>
                        <ul>
                          {mctg.map((list) => (
                            <li
                              className="dropdown"
                              key={list.main_category_id}
                            >
                              <Link to={`/category/${list.main_category_name}`}>
                                {list.main_category_name}
                              </Link>
                              <ul>
                                {sctg
                                  .filter(
                                    (f) =>
                                      f.main_category_id ===
                                      list.main_category_id
                                  )
                                  .map((s) => (
                                    <li>
                                      <Link
                                        to={`/category/${list.main_category_name}/${s.sub_category_name}`}
                                      >
                                        {s.sub_category_name}
                                      </Link>
                                    </li>
                                  ))}
                              </ul>
                            </li>
                          ))}
                        </ul>
                      </li>
                      <li>
                        <a href="/contact-us">Contact Us</a>
                      </li>
                      <li>
                        <a href="/more-details">More</a>
                      </li>
                    </ul>
                  </div>
                </nav>
              </div>
              <div className="menu-right-content clearfix pull-right">
                <ul className="social-style-one clearfix float-right d-lg-block ">
                  <li>
                    <a href={`${list.facebook}`} target="_blank">
                      <i className="fab fa-facebook-f" />
                    </a>
                  </li>
                  <li>
                    <a href={`${list.instagram}`} target="_blank">
                      <i className="fab fa-instagram" />
                    </a>
                  </li>
                  <li>
                    <a href={`${list.linkedin}`} target="_blank">
                      <i className="fab fa-linkedin-in" />
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>

        <div className="sticky-header">
          <div className="auto-container">
            <div className="outer-box clearfix">
              <div className="menu-area pull-left">
                <nav className="main-menu navbar-expand-md navbar-light">
                  <div
                    className="collapse navbar-collapse show clearfix"
                    id="navbarSupportedContent"
                  >
                    <ul className="navigation clearfix">
                      <li className="">
                        <a href="/">Home</a>
                      </li>
                      <li className>
                        <a href="/about-us">About Us</a>
                      </li>
                      <li class="dropdown current">
                        <a href="/">Products</a>
                        <ul>
                          {mctg.map((list) => (
                            <li class="dropdown" key={list.main_category_id}>
                              <Link to={`/category/${list.main_category_name}`}>
                                {list.main_category_name}
                              </Link>
                              <ul>
                                {sctg
                                  .filter(
                                    (f) =>
                                      f.main_category_id ===
                                      list.main_category_id
                                  )
                                  .map((s) => (
                                    <li>
                                      <Link
                                        to={`/category/${list.main_category_name}/${s.sub_category_name}`}
                                      >
                                        {s.sub_category_name}
                                      </Link>
                                    </li>
                                  ))}
                              </ul>
                            </li>
                          ))}
                        </ul>
                      </li>
                      <li>
                        <a href="/contact-us">Contact Us</a>
                      </li>
                      <li>
                        <a href="/more-details">More</a>
                      </li>
                    </ul>
                  </div>
                </nav>
              </div>
              <div className="menu-right-content clearfix pull-right">
                <ul className="social-style-one clearfix">
                  <li>
                    <a href={`${list.facebook}`} target="_blank">
                      <i className="fab fa-facebook-f" />
                    </a>
                  </li>
                  <li>
                    <a href={`${list.instagram}`} target="_blank">
                      <i className="fab fa-instagram" />
                    </a>
                  </li>
                  <li>
                    <a href={`${list.linkedin}`} target="_blank">
                      <i className="fab fa-linkedin-in" />
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </header>

      <div className="mobile-menu">
        <div className="menu-backdrop" />
        <div className="close-btn">
          <i className="fas fa-times" />
        </div>
        <nav className="menu-box">
          <div className="nav-logo">
            <a href="/">
              <img
                src="/assets/images/imon-logo.png"
                width="150px"
                alt="logo"
                title
              />
            </a>
          </div>
          <div className="contact-info">
            <h4>Contact Info</h4>
            <ul>
              <li>{list.location}</li>
              <li>
                <a href={`tel:+${list.num1}`}>+91{list.num1}</a>
              </li>
              <li>
                <a href={`mailto:${list.email1}`}>{list.email1}</a>
              </li>
            </ul>
          </div>
          <div className="social-links">
            <ul className="clearfix">
              <li>
                <a href={`${list.facebook}`}>
                  <span className="fab fa-facebook-square" />
                </a>
              </li>
              <li>
                <a href={`${list.instagram}`}>
                  <span className="fab fa-instagram" />
                </a>
              </li>
              <li>
                <a href={`${list.linkedin}`}>
                  <i className="fab fa-linkedin-in" />
                </a>
              </li>
            </ul>
          </div>
        </nav>
      </div>
      {mctg
        .filter((d) => d.main_category_name === names)
        .map((mlist) => (
          <>
            {sctg
              .filter(
                (d) =>
                  d.main_category_id === mlist.main_category_id &&
                  d.sub_category_name === snames
              )
              .map((slist) => (
                <>
                  {schildctg
                    .filter(
                      (d) =>
                        d.main_category_id === mlist.main_category_id &&
                        d.sub_category_id === slist.sub_category_id &&
                        d.sub_child_category_name === product
                    )
                    .map((list) => (
                      <section
                        key={list.sub_child_category_id}
                        className="page-title"
                        style={{
                          backgroundImage: `url(/subctgImgs/${list.sub_child_category_img}`,
                        }}
                      >
                        <div className="auto-container">
                          <div className="content-box">
                            <div className="title">
                              <h1>{list.sub_child_category_name}</h1>
                            </div>
                            <ul className="bread-crumb clearfix text-left">
                              <li>
                                <a href="/">Home</a>
                              </li>
                              <li>
                                <a
                                  href={`/category/${mlist.main_category_name}`}
                                >
                                  {mlist.main_category_name}
                                </a>
                              </li>
                              <li>
                                <a
                                  href={`/category/${mlist.main_category_name}/${slist.sub_category_name}`}
                                >
                                  {slist.sub_category_name}
                                </a>
                              </li>
                              <li>{list.sub_child_category_name}</li>
                            </ul>
                          </div>
                        </div>
                      </section>
                    ))}
                </>
              ))}
          </>
        ))}
      <section className="shop-page-section">
        <div className="auto-container">
          <div className="row clearfix">
            <div className="col-lg-3 col-md-12 col-sm-12 sidebar-side">
              <div className="blog-sidebar default-sidebar ml-30">
                <div className="sidebar-widget category-widget">
                  {mctg
                    .filter((d) => d.main_category_name === names)
                    .map((mlist) => (
                      <>
                        {sctg
                          .filter(
                            (d) => d.main_category_id === mlist.main_category_id
                          )
                          .map((slist) => (
                            <>
                              <br />
                              <div className="widget-title">
                                <h3>{slist.sub_category_name}</h3>
                              </div>

                              {schildctg
                                .filter(
                                  (d) =>
                                    d.sub_category_id === slist.sub_category_id
                                )
                                .map((sclist) => (
                                  <>
                                    <div
                                      className="widget-content m-2"
                                      ref={ref}
                                    >
                                      <ul className="category-list clearfix">
                                        <li key={sclist.sub_child_category_id}>
                                          <Link
                                            to={`/category/${mlist.main_category_name}/${slist.sub_category_name}/product/${sclist.sub_child_category_name}`}
                                          >
                                            {sclist.sub_child_category_name}
                                          </Link>
                                        </li>
                                      </ul>
                                    </div>
                                  </>
                                ))}
                            </>
                          ))}
                      </>
                    ))}
                </div>
              </div>
            </div>
            <div className="col-lg-9 col-md-12 col-sm-12 content-side">
              <div className="our-shop">
                <div className="row">
                  <div className="col pt-2">
                    {mctg
                      .filter((d) => d.main_category_name === names)
                      .map((mlist) => (
                        <>
                          <a
                            style={{ color: "black" }}
                            href={`/category/${mlist.main_category_name}`}
                          >
                            {mlist.main_category_name}{" "}
                          </a>
                          <i className="fas fa-angle-right"></i>
                          {sctg
                            .filter(
                              (d) =>
                                d.main_category_id === mlist.main_category_id &&
                                d.sub_category_name === snames
                            )
                            .map((slist) => (
                              <>
                                {" "}
                                <a
                                  style={{ color: "black" }}
                                  href={`/category/${mlist.main_category_name}/${slist.sub_category_name}`}
                                >
                                  {slist.sub_category_name}{" "}
                                </a>
                                <i className="fas fa-angle-right pl-2"></i>
                                {schildctg
                                  .filter(
                                    (d) =>
                                      d.main_category_id ===
                                        mlist.main_category_id &&
                                      d.sub_category_id ===
                                        slist.sub_category_id &&
                                      d.sub_child_category_name === product
                                  )
                                  .map((sclist) => (
                                    <> {sclist.sub_child_category_name}</>
                                  ))}
                              </>
                            ))}
                        </>
                      ))}
                  </div>
                  <div className="col-md-auto">
                    <div className="item-shorting clearfix">
                      <div className="text pull-left">
                        <h6>
                          We found
                          <span className="m-2">
                            {schildctg
                              .filter(
                                (d) => d.sub_child_category_name === product
                              )
                              .map((list) => (
                                <>
                                  {
                                    products.filter(
                                      (d) =>
                                        d.sub_child_category_id ===
                                        list.sub_child_category_id
                                    ).length
                                  }
                                </>
                              ))}
                          </span>
                          products for you
                        </h6>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="row clearfix">
                  {schildctg
                    .filter((d) => d.sub_child_category_name === product)
                    .map((list) => (
                      <>
                        {products
                          .filter(
                            (d) =>
                              d.sub_child_category_id ===
                              list.sub_child_category_id
                          )
                          .map((list) => (
                            <div
                              className="col-lg-4 col-md-6 col-sm-12 shop-block"
                              key={list.main_product_id}
                            >
                              <div className="shop-block-one">
                                <div className="inner-box">
                                  <figure className="image-box">
                                    <a
                                      data-toggle="modal"
                                      data-target={`#id${list.main_product_id}`}
                                      data-whatever="@mdo"
                                      className="lightbox-image"
                                    >
                                      <img
                                        src={`https://imontechnologies.in/productImgs/Images/${list.main_product_img}`}
                                        alt={`${list.main_product_img}`}
                                        style={{width: '220px',backgroundColor:'white'}}
                                      />
                                    </a>
                                  </figure>
                                  <div className="content-box">
                                    <div class="share-option">
                                      <ul className="link-box">
                                        <li>
                                          <a
                                            style={{
                                              color: "white",
                                            }}
                                            href="#"
                                            data-toggle="modal"
                                            data-target={`#id${list.main_product_id}`}
                                            data-whatever="@mdo"
                                            className="lightbox-image"
                                          >
                                            <i class="icon-link"></i>
                                          </a>
                                        </li>
                                      </ul>
                                    </div>
                                    <div className="lower-content">
                                      <h6>
                                        <a
                                          data-toggle="modal"
                                          data-target={`#id${list.main_product_id}`}
                                          data-whatever="@mdo"
                                          className="lightbox-image"
                                        >
                                          {list.main_product_name}
                                        </a>
                                      </h6>
                                      <span className="price" style={{height:'150px'}}>
                                        {list.main_product_des}
                                        <br />
                                      </span>
                                      <div
                                        className="modal fade"
                                        id={`id${list.main_product_id}`}
                                        tabIndex={-1}
                                        role="dialog"
                                        aria-labelledby="exampleModalCenterTitle"
                                        aria-hidden="true"
                                      >
                                        <div
                                          className="modal-dialog modal-dialog-centered modal-lg"
                                          role="document"
                                        >
                                          <div className="modal-content shadow-lg p-3 mb-5">
                                            <div className="modal-header">
                                              <h5
                                                className="modal-title "
                                                id={`id${list.main_product_id}`}
                                              >
                                                {list.main_product_name}
                                              </h5>
                                              <button
                                                type="button"
                                                className="close"
                                                data-dismiss="modal"
                                                aria-label="Close"
                                              >
                                                <span aria-hidden="true">
                                                  ×
                                                </span>
                                              </button>
                                            </div>
                                            <div className="modal-body">
                                              <div className="container-fluid">
                                                <div className="row">
                                                  <div className="col-md-6">
                                                    <figure classname="image-box">
                                                      <img
                                                        src={`/productImgs/Images/${list.main_product_img}`}
                                                        alt={`${list.main_product_img}`}
                                                        height={"10px"}
                                                        width={"200px"}
                                                      />
                                                    </figure>
                                                    <p className="text-left">
                                                      {
                                                        list.main_product_otherdes
                                                      }
                                                    </p>
                                                  </div>
                                                  <div className="col-md-6">
                                                    <div
                                                      style={{
                                                        display: "flex",
                                                        justifyContent:
                                                          "flex-end",
                                                        flexWrap: "wrap",
                                                      }}
                                                    >
                                                      {icons
                                                        .filter(
                                                          (d) =>
                                                            d.main_product_id ===
                                                            list.main_product_id
                                                        )
                                                        .map((i) => (
                                                          <div
                                                            class="col-lg-4"
                                                            style={{
                                                              height: "100%",
                                                              maxWidth: "50%",
                                                              marginBottom:
                                                                "10px",
                                                              padding: "0px",
                                                            }}
                                                          >
                                                            <div class="chooseus-block-two">
                                                              <div
                                                                class="inner-box "
                                                                style={{
                                                                  padding:
                                                                    "8px",
                                                                  marginBottom:
                                                                    "0px",
                                                                  fontSize:
                                                                    "15px",
                                                                  width:
                                                                    "110px",
                                                                  height:
                                                                    "160px",
                                                                  borderBottom:
                                                                    "3px solid #7dbc26",
                                                                }}
                                                              >
                                                                <div class="icon-box">
                                                                  <img
                                                                    src={`/Icons/${i.product_icon_img}`}
                                                                    height="20px"
                                                                    width={
                                                                      "30px"
                                                                    }
                                                                    alt={`${i.product_icon_img}`}
                                                                  ></img>
                                                                </div>

                                                                {
                                                                  i.product_icon_name
                                                                }
                                                              </div>
                                                            </div>
                                                          </div>
                                                        ))}
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                      <br />
                                      <button
                                        data-toggle="modal"
                                        data-target={`#exam${list.main_product_id}`}
                                        data-whatever="@mdo"
                                        className="theme-btn style-one"
                                        type="submit"
                                        name="submit-form"
                                        onClick={() =>
                                          formik.setFieldValue(
                                            "productpdf",
                                            list.main_product_pdf
                                          )
                                        }
                                      >
                                        Inquiry Now
                                      </button>
                                      <div
                                        className="modal fade"
                                        id={`exam${list.main_product_id}`}
                                        tabIndex={-1}
                                        role="dialog"
                                        aria-labelledby={`exampleModalLabel`}
                                        aria-hidden="true"
                                      >
                                        <div
                                          className="modal-dialog modal-lg"
                                          role="document"
                                        >
                                          <div
                                            className="modal-content shadow-lg p-3 mb-5 bg-dark text-white"
                                            style={{ borderRadius: "1rem" }}
                                          >
                                            <div className="modal-header">
                                              <h5
                                                className="modal-title"
                                                id={`exampleModalLabel`}
                                              >
                                                <div class="widget-title">
                                                  <h3
                                                    style={{ color: "white" }}
                                                  >
                                                    {" "}
                                                    {list.main_product_name}
                                                  </h3>
                                                </div>
                                              </h5>
                                              <button
                                                style={{ color: "white" }}
                                                type="button"
                                                className="close"
                                                data-dismiss="modal"
                                                aria-label="Close"
                                              >
                                                <span aria-hidden="true">
                                                  ×
                                                </span>
                                              </button>
                                            </div>
                                            <div className="modal-body text-white">
                                              <form
                                                className="text-left"
                                                onSubmit={formik.handleSubmit}
                                              >
                                                <div class="row">
                                                  <div class="col-lg-6 col-md-6 col-sm-12 column">
                                                    <div className="form-group">
                                                      <label
                                                        htmlFor="recipient-name"
                                                        className="col-form-label"
                                                      >
                                                        Name
                                                      </label>
                                                      <input
                                                        name="name"
                                                        type="text"
                                                        className="form-control"
                                                        id="recipient-name"
                                                        value={
                                                          formik.values.name
                                                        }
                                                        onChange={
                                                          formik.handleChange
                                                        }
                                                      />
                                                    </div>
                                                  </div>
                                                  <div class="col">
                                                    <div className="form-group">
                                                      <label
                                                        htmlFor="company"
                                                        className="col-form-label"
                                                      >
                                                        Company Name
                                                      </label>
                                                      <input
                                                        name="company"
                                                        type="text"
                                                        className="form-control"
                                                        id="company"
                                                        value={
                                                          formik.values.company
                                                        }
                                                        onChange={
                                                          formik.handleChange
                                                        }
                                                      />
                                                    </div>
                                                  </div>
                                                </div>
                                                <div class="row">
                                                  <div class="col-lg-6 col-md-6 col-sm-12 column">
                                                    <div className="form-group">
                                                      <label
                                                        htmlFor="city"
                                                        className="col-form-label"
                                                      >
                                                        City
                                                      </label>
                                                      <input
                                                        name="city"
                                                        type="text"
                                                        className="form-control"
                                                        id="city"
                                                        value={
                                                          formik.values.city
                                                        }
                                                        onChange={
                                                          formik.handleChange
                                                        }
                                                      />
                                                    </div>
                                                  </div>

                                                  <div class="col">
                                                    <div className="form-group">
                                                      <label
                                                        htmlFor="phone"
                                                        className="col-form-label"
                                                      >
                                                        Mobile No.
                                                      </label>
                                                      <input
                                                        name="phone"
                                                        value={
                                                          formik.values.phone
                                                        }
                                                        onChange={
                                                          formik.handleChange
                                                        }
                                                        type="text"
                                                        className="form-control"
                                                        id="phone"
                                                      />
                                                    </div>
                                                  </div>
                                                </div>
                                                <div class="row">
                                                  <div class="col-lg-6 col-md-6 col-sm-12 column">
                                                    <div className="form-group">
                                                      <label
                                                        htmlFor="email"
                                                        className="col-form-label"
                                                      >
                                                        Email ID
                                                      </label>
                                                      <input
                                                        name="email"
                                                        value={
                                                          formik.values.email
                                                        }
                                                        onChange={
                                                          formik.handleChange
                                                        }
                                                        type="email"
                                                        className="form-control"
                                                        id="email"
                                                      />
                                                    </div>
                                                  </div>
                                                  <div class="col-lg-6 col-md-6 col-sm-12 column">
                                                    <div className="form-group">
                                                      <label
                                                        htmlFor="email"
                                                        className="col-form-label"
                                                      >
                                                        Who You Are?
                                                      </label>
                                                      <select
                                                        defaultValue="------"
                                                        name="custtype"
                                                        onChange={
                                                          formik.handleChange
                                                        }
                                                        onBlur={
                                                          formik.handleBlur
                                                        }
                                                        className="form-control"
                                                      >
                                                        <option selected>
                                                          Who You Are?
                                                        </option>
                                                        <option
                                                          value={`Reseller`}
                                                        >
                                                          Reseller
                                                        </option>
                                                        <option value={`SI`}>
                                                          SI
                                                        </option>
                                                        <option
                                                          value={`Fabricator`}
                                                        >
                                                          Fabricator
                                                        </option>
                                                        <option
                                                          value={`End Customer`}
                                                        >
                                                          End Customer
                                                        </option>
                                                      </select>
                                                    </div>
                                                  </div>
                                                </div>
                                                <div className="form-group">
                                                  <label
                                                    htmlFor="msg"
                                                    className="col-form-label"
                                                  >
                                                    Your Message
                                                  </label>
                                                  <textarea
                                                    name="msg"
                                                    value={formik.values.msg}
                                                    onChange={
                                                      formik.handleChange
                                                    }
                                                    type="text"
                                                    className="form-control"
                                                    id="msg"
                                                  />
                                                </div>

                                                <button
                                                  onClick={() =>
                                                    formik.setFieldValue(
                                                      "productName",
                                                      list.main_product_name
                                                    )
                                                  }
                                                  type="submit"
                                                  className="theme-btn style-one"
                                                >
                                                  Submit
                                                </button>
                                                <ToastContainer />
                                              </form>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          ))}
                      </>
                    ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <Dealer />

      <FooterPage />
    </div>
  );
}
