import React, { useState, useEffect, useRef } from "react";
import { Dealer } from "./Dealer";
import FooterPage from "../Layouts/FooterPage";
import { Link } from "react-router-dom";
import axios from "axios";

function VideoPage() {
  const [mctg, setMctg] = useState([]);
  const [sctg, setSctg] = useState([]);
  const [list, setList] = useState([]);

  const ref = useRef(null);

  useEffect(() => {
    allCtg();
    ProfileList();
    allSubCtg();
  }, []);

  const allCtg = () => {
    axios
      .get("https://imontechnologies.in/apps/MainCategory/all.php")
      .then((res) => {
        // console.log(res.data.mctglist.mctgdata);
        setMctg(res.data.mctglist.mctgdata);
      });
  };
  const allSubCtg = () => {
    axios
      .get("https://imontechnologies.in/apps/SubCategory/all.php")
      .then((res) => {
        // console.log(res.data.sctglist.sctgdata);
        setSctg(res.data.sctglist.sctgdata);
      })
      .catch((err) => console.log(err));
  };
  const ProfileList = () => {
    axios
      .get(`https://imontechnologies.in/apps/Profile/all.php`)
      .then((res) => {
        // console.log(res.data.profilelist.profiledata);
        setList(res.data.profilelist.profiledata[0]);
      });
  };
  const handleClick = () => {
    ref.current?.scrollIntoView({ behavior: "smooth" });
  };

  function openNav() {
    document.getElementById("mySidenav").style.width = "250px";
  }

  function closeNav() {
    document.getElementById("mySidenav").style.width = "0";
  }
  return (
    <div>
      <div className="boxed_wrapper">
        {/* main header */}
        <header className="main-header style-one">
          <div className="page-header-mobile-info">
            <div className="page-header-mobile-info-content">
              <div className="header-top">
                <div className="auto-container">
                  <div className="top-inner clearfix">
                    <ul className="info clearfix pull-left">
                      <li>
                        <i className="icon-hour" />
                        {list.hrs}
                      </li>
                      <li>
                        <i className="icon-placeholder" />
                        {list.location}
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="header-upper">
                <div className="auto-container">
                  <div className="upper-inner clearfix">
                    <div className="logo-box pull-left">
                      <figure className="logo">
                        <a href="/">
                          <img src="/assets/images/imon-logo.png" alt="logo" />
                        </a>
                      </figure>
                    </div>
                    <ul className="info-list clearfix pull-right">
                      <li>
                        <i className="icon-email" />
                        <p>Mail Us On</p>
                        <h5>
                          <a href={`mailto:${list.email1}`}>{list.email1}</a>
                        </h5>
                      </li>
                      <li>
                        <i className="icon-smartphone" />
                        <p>Make a Call</p>
                        <h5>
                          <a href={`tel:${list.num1}`}>+91{list.num1}</a>
                        </h5>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="header-top auto-hidden">
            <div className="auto-container">
              <div className="top-inner clearfix">
                <ul className="info clearfix pull-left">
                  <li>
                    <i className="icon-hour" />
                    {list.hrs}
                  </li>
                  <li>
                    <i className="icon-placeholder" />
                    {list.location}
                  </li>
                </ul>
              </div>
            </div>
          </div>

          <div className="header-upper auto-hidden">
            <div className="auto-container">
              <div className="upper-inner clearfix">
                <div className="logo-box pull-left">
                  <figure className="logo">
                    <a href="/">
                      <img
                        src="/assets/images/imon-logo.png"
                        style={{ width: 120 }}
                        alt="logo"
                      />
                    </a>
                  </figure>
                </div>
                <ul className="info-list clearfix pull-right">
                  <li>
                    <i className="icon-email" />
                    <p>Mail Us On</p>
                    <h5>
                      <a href={`mailto:${list.email1}`}>{list.email1}</a>
                    </h5>
                  </li>
                  <li>
                    <i className="icon-smartphone" />
                    <p>Make a Call</p>
                    <h5>
                      <a href={`tel:${list.num1}`}>+91{list.num1}</a>
                    </h5>
                  </li>
                  <li className="btn-box">
                    <button
                      className="theme-btn style-one"
                      onClick={handleClick}
                    >
                      Dealers Inquiry
                    </button>
                  </li>
                </ul>
              </div>
            </div>
          </div>

          <div className="header-lower">
            <div className="auto-container">
              <div className="outer-box clearfix">
                <div className="menu-area clearfix pull-left">
                  <div className="mobile-nav-toggler menu-left-content clearfix">
                    <div
                      className="form-group"
                      style={{ marginBottom: "0rem" }}
                    >
                      <div
                        className="row justify-content-md-start"
                        style={{ display: "inline-flex" }}
                      >
                        <div className="col mt-3" onClick={openNav}>
                          <i className="icon-bar" />
                          <i className="icon-bar" />
                          <i className="icon-bar" />
                        </div>

                        <img
                          src="assets/images/imon-logo.png"
                          width="100px"
                          className="p-3"
                          alt="logo"
                          title
                        />
                      </div>
                    </div>

                    <div id="mySidenav" class="sidenav">
                      <a
                        href="javascript:void(0)"
                        class="closebtn "
                        onClick={closeNav}
                      >
                        &times;
                      </a>
                      <img
                        src="assets/images/imon-logo.png"
                        width="150px"
                        className="pl-3"
                        alt="logo"
                        title
                      />

                      <ul className="navigation clearfix">
                        <li className="">
                          <a href="/" className="nav-item nav-link">
                            Home
                          </a>
                        </li>
                        <li className>
                          <a href="/about-us">About Us</a>
                        </li>
                        {mctg.map((list) => (
                          <li className="dropdown" key={list.main_category_id}>
                            <Link to={`/category/${list.main_category_name}`}>
                              {list.main_category_name}
                            </Link>
                          </li>
                        ))}
                        <li>
                          <a href="/contact-us">Contact Us</a>
                        </li>
                        <li className="current">
                          <a href="/more-details">More</a>
                        </li>
                      </ul>
                    </div>
                  </div>

                  <nav className="main-menu navbar-expand-md navbar-light">
                    <div
                      className="collapse navbar-collapse show clearfix"
                      id="navbarSupportedContent"
                    >
                      <ul className="navigation clearfix navbar-nav">
                        <li className="">
                          <a href="/">Home</a>
                        </li>
                        <li className="">
                          <a href="/about-us">About Us</a>
                        </li>
                        <li className="dropdown">
                          <a href="/">Products</a>
                          <ul>
                            {mctg.map((list) => (
                              <li
                                className="dropdown"
                                key={list.main_category_id}
                              >
                                <Link
                                  to={`/category/${list.main_category_name}`}
                                >
                                  {list.main_category_name}
                                </Link>
                                <ul>
                                  {sctg
                                    .filter(
                                      (f) =>
                                        f.main_category_id ===
                                        list.main_category_id
                                    )
                                    .map((s) => (
                                      <li>
                                        <Link
                                          to={`/category/${list.main_category_name}/${s.sub_category_name}`}
                                        >
                                          {s.sub_category_name}
                                        </Link>
                                      </li>
                                    ))}
                                </ul>
                              </li>
                            ))}
                          </ul>
                        </li>
                        <li>
                          <a href="/contact-us">Contact Us</a>
                        </li>
                        <li className="current">
                          <a href="/more-details">More</a>
                        </li>
                      </ul>
                    </div>
                  </nav>
                </div>
                <div className="menu-right-content clearfix pull-right">
                  <ul className="social-style-one clearfix float-right d-lg-block ">
                    <li>
                      <a href={`${list.facebook}`} target="_blank">
                        <i className="fab fa-facebook-f" />
                      </a>
                    </li>
                    <li>
                      <a href={`${list.instagram}`} target="_blank">
                        <i className="fab fa-instagram" />
                      </a>
                    </li>
                    <li>
                      <a href={`${list.linkedin}`} target="_blank">
                        <i className="fab fa-linkedin-in" />
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>

          <div className="sticky-header">
            <div className="auto-container">
              <div className="outer-box clearfix">
                <div className="menu-area pull-left">
                  <nav className="main-menu navbar-expand-md navbar-light">
                    <div
                      className="collapse navbar-collapse show clearfix"
                      id="navbarSupportedContent"
                    >
                      <ul className="navigation clearfix">
                        <li className="">
                          <a href="/">Home</a>
                        </li>
                        <li className="">
                          <a href="/about-us">About Us</a>
                        </li>
                        <li className="dropdown">
                          <a href="#">Products</a>
                          <ul>
                            {mctg.map((list) => (
                              <li
                                className="dropdown"
                                key={list.main_category_id}
                              >
                                <Link
                                  to={`/category/${list.main_category_name}`}
                                >
                                  {list.main_category_name}
                                </Link>
                                <ul>
                                  {sctg
                                    .filter(
                                      (f) =>
                                        f.main_category_id ===
                                        list.main_category_id
                                    )
                                    .map((s) => (
                                      <li>
                                        <Link
                                          to={`/category/${list.main_category_name}/${s.sub_category_name}`}
                                        >
                                          {s.sub_category_name}
                                        </Link>
                                      </li>
                                    ))}
                                </ul>
                              </li>
                            ))}
                          </ul>
                        </li>
                        <li>
                          <a href="/contact-us">Contact Us</a>
                        </li>
                        <li className="current">
                          <a href="/more-details">More</a>
                        </li>
                      </ul>
                    </div>
                  </nav>
                </div>
                <div className="menu-right-content clearfix pull-right">
                  <ul className="social-style-one clearfix">
                    <li>
                      <a href={`${list.facebook}`} target="_blank">
                        <i className="fab fa-facebook-f" />
                      </a>
                    </li>
                    <li>
                      <a href={`${list.instagram}`} target="_blank">
                        <i className="fab fa-instagram" />
                      </a>
                    </li>
                    <li>
                      <a href={`${list.linkedin}`} target="_blank">
                        <i className="fab fa-linkedin-in" />
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </header>
        {/* main-header end */}
        {/* Mobile Menu  */}
        <div className="mobile-menu">
          <div className="menu-backdrop" />
          <div className="close-btn">
            <i className="fas fa-times" />
          </div>
          <nav className="menu-box">
            <div className="nav-logo">
              <a href="/">
                <img
                  src="assets/images/imon-logo.png"
                  width="150px"
                  alt
                  title
                />
              </a>
            </div>
            <div className="menu-outer">
              {/*Here Menu Will Come Automatically Via Javascript / Same Menu as in Header*/}
            </div>
            <div className="contact-info">
              <h4>Contact Info</h4>
              <ul>
                <li>Lal Darwaja, Surat</li>
                <li>
                  <a href="tel:+8801682648101">+91 1234567890</a>
                </li>
                <li>
                  <a href="mailto:info@example.com">info@imontechnology.com</a>
                </li>
              </ul>
            </div>
            <div className="social-links">
              <ul className="clearfix">
                <li>
                  <a href="/">
                    <span className="fab fa-twitter" />
                  </a>
                </li>
                <li>
                  <a href="/">
                    <span className="fab fa-facebook-square" />
                  </a>
                </li>
                <li>
                  <a href="/">
                    <span className="fab fa-instagram" />
                  </a>
                </li>
                <li>
                  <a href="/">
                    <span className="fab fa-youtube" />
                  </a>
                </li>
              </ul>
            </div>
          </nav>
        </div>
        {/* End Mobile Menu */}
        {/* Page Title */}
        <section
          className="page-title"
          style={{
            backgroundImage: "url(assets/images/background/swing-1.webp)",
          }}
        >
          <div className="auto-container">
            <div className="content-box">
              <div className="title">
                <h1>DESIGNED AND MANUFACTURED IN ITALY FOR PLANET EARTH.</h1>
              </div>
              <ul className="bread-crumb clearfix d-none">
                <li>
                  <a href="/">Home</a>
                </li>
                <li>Pages</li>
                <li>Products</li>
              </ul>
            </div>
          </div>
        </section>
        {/* End Page Title */}
        {/* project-page-section */}
        <section class="video-section mt-5">
          <div class="auto-container">
            <div class="inner-container text-center">
              <iframe
                width="100%"
                height="515"
                src="https://www.youtube.com/embed/-gFvwTvxp28"
                title="YouTube video player"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                allowfullscreen
              ></iframe>
            </div>
          </div>
        </section>

        <section className="project-page-section title-over-two">
          <div className="auto-container">
            <div className="sortable-masonry">
              <div className="filters centred mt-5">
                <ul className="filter-tabs filter-btns clearfix"></ul>
              </div>
              <h1 className="text-center">
                DISCOVER OUR PRODUCTION CAPACITY 100% MADE IN ITALY
              </h1>
              <div className="filters centred mt-5">
                <ul className="filter-tabs filter-btns clearfix"></ul>
              </div>
              <div className="items-container row clearfix">
                <div className="col-lg-4 col-md-6 col-sm-12 masonry-item small-column all outdoor alaram installation">
                  <div className="project-block-one">
                    <div className="inner-box">
                      <iframe
                        width="410"
                        height="230"
                        src="https://www.youtube.com/embed/htt-5-YrWwY?rel=0&showinfo=0"
                      ></iframe>

                      <div className="text">
                        <p>Mechanical</p>
                        <h4>
                          <a href="#"> production</a>
                        </h4>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4 col-md-6 col-sm-12 masonry-item small-column all alaram outdoor wireless">
                  <div className="project-block-one">
                    <div className="inner-box">
                      <iframe
                        width="410"
                        height="230"
                        src="https://www.youtube.com/embed/2I2VkFb6keg?rel=0&showinfo=0"
                      ></iframe>
                      <div className="text">
                        <p>Gear</p>
                        <h4>
                          <a href="#"> production</a>
                        </h4>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4 col-md-6 col-sm-12 masonry-item small-column all outdoor installation">
                  <div className="project-block-one">
                    <div className="inner-box">
                      <iframe
                        width="410"
                        height="230"
                        src="https://www.youtube.com/embed/Gl7i4R96yq0?rel=0&showinfo=0"
                      ></iframe>
                      <div className="text">
                        <p>Digital motors</p>
                        <h4>
                          <a href="#">production</a>
                        </h4>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4 col-md-6 col-sm-12 masonry-item small-column all alaram installation wireless">
                  <div className="project-block-one">
                    <div className="inner-box">
                      <iframe
                        width="410"
                        height="230"
                        src="https://www.youtube.com/embed/QtKw_HDEyj8?rel=0&showinfo=0"
                      ></iframe>
                      <div className="text">
                        <p>Quality</p>
                        <h4>
                          <a href="#"> controls</a>
                        </h4>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4 col-md-6 col-sm-12 masonry-item small-column all outdoor alaram installation">
                  <div className="project-block-one">
                    <div className="inner-box">
                      <iframe
                        width="410"
                        height="230"
                        src="https://www.youtube.com/embed/1j2uXns7phE?rel=0&showinfo=0"
                      ></iframe>
                      <div className="text">
                        <p>ENGINEERING</p>
                        <h4>
                          <a href="#"> & ELECTRONICS</a>
                        </h4>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4 col-md-6 col-sm-12 masonry-item small-column all alaram outdoor wireless">
                  <div className="project-block-one">
                    <div className="inner-box">
                      <iframe
                        width="410"
                        height="230"
                        src="https://www.youtube.com/embed/id1eN3TNEbs?rel=0&showinfo=0"
                      ></iframe>
                      <div className="text">
                        <p>IN-HOUSE</p>
                        <h4>
                          <a href="#"> ASSEMBLY</a>
                        </h4>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-12 col-md-6 col-sm-12 masonry-item small-column all alaram outdoor wireless">
                  <div className="project-block-one">
                    <div className="inner-box text-center">
                      <iframe
                        width="100%"
                        height="515"
                        src="https://www.youtube.com/embed/ZFm-KzZRJdg?rel=0&amp;showinfo=0"
                        title="YouTube video player"
                        frameborder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                        allowfullscreen
                      ></iframe>
                      <div className="text">
                        <p>LINEAR</p>
                        <h4>
                          <a href="#">MECHANICAL PRODUCTION</a>
                        </h4>
                      </div>
                    </div>
                  </div>
                </div>
                <a href="https://imontechnologies.in/" target="_blank">
                  <h1 style={{ marginLeft: "400px" }}>imontechnologies.in</h1>
                </a>
              </div>
            </div>
          </div>
        </section>

        {/* project-page-section end */}
        {/* main-footer */}

        <Dealer />
        <FooterPage />
        {/* main-footer end */}
        {/*Scroll to top*/}
        <button className="scroll-top scroll-to-target" data-target="html">
          <span className="fal fa-angle-up" />
        </button>
      </div>
    </div>
  );
}

export default VideoPage;
