import React from "react";
import { useFormik } from "formik";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export const Dealer = () => {
  const formik = useFormik({
    initialValues: {
      name: "",
      company: "",
      email: "",
      phone: "",
      city: "",
      custtype: "",
      productname: "",
      msg: "",
    },

    onSubmit: (values) => {
      // console.log(values);

      axios
        .post(`https://imontechnologies.in/Inquirymail.php`, list)
        .then((res) => {
          console.log(res.data);
          if (res.data.success === true) {
            toast.info("Thank you for getting in touch!", { autoClose: 5000 });
          } else {
            toast.error("Please fill all the required fields!", {
              autoClose: 3000,
            });
          }
        });
      formik.resetForm();
    },
  });
  const list = {
    name: formik.values.name,
    company: formik.values.company,
    email: formik.values.email,
    phone: formik.values.phone,
    city: formik.values.city,
    custtype: formik.values.custtype,
    productname: formik.values.productname,
    msg: formik.values.msg,
  };
  return (
    <section className="newsletter-section">
      <div className="bg-shape" />
      <div className="auto-container">
        <div className="row clearfix">
          <div className="content_block_5">
            <div
              className="content-box"
              style={{
                borderTopLeftRadius: "25px",
                borderTopRightRadius: "25px",
              }}
            >
              <h3>Which Product are You Intrested in?</h3>
              <form onSubmit={formik.handleSubmit} className="quote-form">
                <div className="row clearfix">
                  <div className="col-lg-3 col-md-12 col-sm-12 column">
                    <div className=" form-group">
                      <input
                        type="text"
                        name="name"
                        placeholder="Your Full Name"
                        value={formik.values.name}
                        onChange={formik.handleChange}
                        required
                      />
                    </div>
                  </div>
                  <div className="col-lg-3 col-md-12 col-sm-12 column">
                    <div className="form-group">
                      <input
                        type="text"
                        name="company"
                        placeholder="Your Company Name"
                        value={formik.values.company}
                        onChange={formik.handleChange}
                        required
                      />
                    </div>
                  </div>
                  <div className="col-lg-3 col-md-12 col-sm-12 column">
                    <div className="form-group">
                      <input
                        type="email"
                        name="email"
                        value={formik.values.email}
                        onChange={formik.handleChange}
                        placeholder="Your Email"
                        required
                      />
                    </div>
                  </div>
                  <div className="col-lg-3 col-md-12 col-sm-12 column">
                    <div className="form-group">
                      <input
                        type="text"
                        name="phone"
                        placeholder="Your Mobile No"
                        value={formik.values.phone}
                        onChange={formik.handleChange}
                        required
                      />
                    </div>
                  </div>
                  <div className="col-lg-3 col-md-12 col-sm-12 column">
                    <div className="form-group">
                      <input
                        type="text"
                        name="city"
                        placeholder="Your City"
                        value={formik.values.city}
                        onChange={formik.handleChange}
                        required
                      />
                    </div>
                  </div>
                  <div class="col-lg-3 col-md-12 col-sm-12 column">
                    <div class="form-group">
                      <div class="select-box">
                        <select
                          class="nice-select wide"
                          name="custtype"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                        >
                          <option
                            data-display="Who You Are?"
                            className="option selected focus"
                          >
                            Who You Are?
                          </option>
                          <option class="option" value="Reseller">
                            Reseller
                          </option>
                          <option class="option" value="SI">
                            SI
                          </option>
                          <option class="option" value="Fabricator">
                            Fabricator
                          </option>
                          <option class="option" value="End Customer">
                            End Customer
                          </option>
                        </select>
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-6 col-md-12 col-sm-12 column">
                    <div class="form-group">
                      <div class="select-box">
                        <select
                          class="nice-select wide"
                          name="productname"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                        >
                          <option
                            data-display="Which Product are you intrested?"
                            className="option selected focus"
                          >
                            Which Product are you intrested?
                          </option>
                          <option class="option" value="Sliding Gate Motor">
                            Sliding Gate Motor
                          </option>
                          <option class="option" value="Swing Gate Motor">
                            Swing Gate Motor
                          </option>
                          <option class="option" value="Automatic Boom Barrier">
                            Automatic Boom Barrier
                          </option>
                        </select>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-12 col-sm-12 column">
                    <div className="form-group">
                      <textarea
                        name="msg"
                        value={formik.values.msg}
                        onChange={formik.handleChange}
                        placeholder="Message..."
                        defaultValue={""}
                      />
                    </div>
                  </div>
                  <div className="col-lg-3 pt-3 col-md-12 col-sm-12 column">
                    <div className="btn-box">
                      <button type="submit" className="theme-btn style-one">
                        Submit
                      </button>
                      <ToastContainer />
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
