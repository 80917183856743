import React, { useEffect, useState } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import FooterPage from "../../Layouts/FooterPage";
import { Dealer } from "../../Pages/Dealer";
import { useRef } from "react";
export default function AboutPage() {
  const [mctg, setMctg] = useState([]);
  const [sctg, setSctg] = useState([]);
  const [adata, setAdata] = useState([]);
  const [tdata, setTdata] = useState([]);
  const [list, setList] = useState([]);

  document.title = "About Us - Imon Technologies Pvt Ltd";

  useEffect(() => {
    allCtg();
    allSubCtg();
    aboutData();
    teamdata();
    ProfileList();
  }, []);
  const ref = useRef(null);

  const handleClick = () => {
    ref.current?.scrollIntoView({ behavior: "smooth" });
  };

  const allCtg = () => {
    axios
      .get("https://imontechnologies.in/apps/MainCategory/all.php")
      .then((res) => {
        // console.log(res.data.mctglist.mctgdata);
        setMctg(res.data.mctglist.mctgdata);
      });
  };

  const allSubCtg = () => {
    axios
      .get("https://imontechnologies.in/apps/SubCategory/all.php")
      .then((res) => {
        // console.log(res.data.sctglist.sctgdata);
        setSctg(res.data.sctglist.sctgdata);
      })
      .catch((err) => console.log(err));
  };

  const aboutData = () => {
    axios.get(`https://imontechnologies.in/apps/About/all.php`).then((res) => {
      // console.log(res.data.aboutlist.aboutdata);
      setAdata(res.data.aboutlist.aboutdata[0]);
    });
  };

  const ProfileList = () => {
    axios
      .get(`https://imontechnologies.in/apps/Profile/all.php`)
      .then((res) => {
        // console.log(res.data.profilelist.profiledata);
        setList(res.data.profilelist.profiledata[0]);
      });
  };

  const teamdata = () => {
    axios.get(`https://imontechnologies.in/apps/Team/all.php`).then((res) => {
      // console.log(res.data.teamlist.teamdata);
      setTdata(res.data.teamlist.teamdata);
    });
  };
  function openNav() {
    document.getElementById("mySidenav").style.width = "250px";
  }

  function closeNav() {
    document.getElementById("mySidenav").style.width = "0";
  }
  return (
    <div className="boxed_wrapper">
      <header className="main-header style-one">
        <div className="page-header-mobile-info">
          <div className="page-header-mobile-info-content">
            <div className="header-top">
              <div className="auto-container">
                <div className="top-inner clearfix">
                  <ul className="info clearfix pull-left">
                    <li>
                      <i className="icon-hour" />
                      {list.hrs}
                    </li>
                    <li>
                      <i className="icon-placeholder" />
                      {list.location}
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="header-upper">
              <div className="auto-container">
                <div className="upper-inner clearfix">
                  <div className="logo-box pull-left">
                    <figure className="logo">
                      <a href="/">
                        <img src="/assets/images/imon-logo.png" alt="logo" />
                      </a>
                    </figure>
                  </div>
                  <ul className="info-list clearfix pull-right">
                    <li>
                      <i className="icon-email" />
                      <p>Mail Us On</p>
                      <h5>
                        <a href={`mailto:${list.email1}`}>{list.email1}</a>
                      </h5>
                    </li>
                    <li>
                      <i className="icon-smartphone" />
                      <p>Make a Call</p>
                      <h5>
                        <a href={`tel:${list.num1}`}>+91{list.num1}</a>
                      </h5>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="header-top auto-hidden">
          <div className="auto-container">
            <div className="top-inner clearfix">
              <ul className="info clearfix pull-left">
                <li>
                  <i className="icon-hour" />
                  {list.hrs}
                </li>
                <li>
                  <i className="icon-placeholder" />
                  {list.location}
                </li>
              </ul>
            </div>
          </div>
        </div>

        <div className="header-upper auto-hidden">
          <div className="auto-container">
            <div className="upper-inner clearfix">
              <div className="logo-box pull-left">
                <figure className="logo">
                  <a href="/">
                    <img
                      src="/assets/images/imon-logo.png"
                      style={{ width: 120 }}
                      alt="logo"
                    />
                  </a>
                </figure>
              </div>
              <ul className="info-list clearfix pull-right">
                <li>
                  <i className="icon-email" />
                  <p>Mail Us On</p>
                  <h5>
                    <a href={`mailto:${list.email1}`}>{list.email1}</a>
                  </h5>
                </li>
                <li>
                  <i className="icon-smartphone" />
                  <p>Make a Call</p>
                  <h5>
                    <a href={`tel:${list.num1}`}>+91{list.num1}</a>
                  </h5>
                </li>
                <li className="btn-box">
                  <button className="theme-btn style-one" onClick={handleClick}>
                    Dealers Inquiry
                  </button>
                </li>
              </ul>
            </div>
          </div>
        </div>

        <div className="header-lower">
          <div className="auto-container">
            <div className="outer-box clearfix">
              <div className="menu-area clearfix pull-left">
                <div className="mobile-nav-toggler menu-left-content clearfix">
                  <div className="form-group" style={{ marginBottom: "0rem" }}>
                    <div
                      className="row justify-content-md-start"
                      style={{ display: "inline-flex" }}
                    >
                      <div className="col mt-3" onClick={openNav}>
                        <i className="icon-bar" />
                        <i className="icon-bar" />
                        <i className="icon-bar" />
                      </div>

                      <img
                        src="assets/images/imon-logo.png"
                        width="100px"
                        className="p-3"
                        alt="logo"
                        title
                      />
                    </div>
                  </div>

                  <div id="mySidenav" class="sidenav">
                    <a
                      href="javascript:void(0)"
                      class="closebtn "
                      onClick={closeNav}
                    >
                      &times;
                    </a>
                    <img
                      src="assets/images/imon-logo.png"
                      width="150px"
                      className="pl-3"
                      alt="logo"
                      title
                    />

                    <ul className="navigation clearfix">
                      <li className="current">
                        <a href="/" className="nav-item nav-link">
                          Home
                        </a>
                      </li>
                      <li className>
                        <a href="/about-us">About Us</a>
                      </li>
                      {mctg.map((list) => (
                        <li className="dropdown" key={list.main_category_id}>
                          <Link to={`/category/${list.main_category_name}`}>
                            {list.main_category_name}
                          </Link>
                        </li>
                      ))}
                      <li>
                        <a href="/contact-us">Contact Us</a>
                      </li>
                      <li className>
                        <a href="/more-details">More</a>
                      </li>
                    </ul>
                  </div>
                </div>

                <nav className="main-menu navbar-expand-md navbar-light">
                  <div
                    className="collapse navbar-collapse show clearfix"
                    id="navbarSupportedContent"
                  >
                    <ul className="navigation clearfix navbar-nav">
                      <li className="">
                        <a href="/">Home</a>
                      </li>
                      <li className='current'>
                        <a href="/about-us">About Us</a>
                      </li>
                      <li className="dropdown">
                        <a href="/">Products</a>
                        <ul>
                          {mctg.map((list) => (
                            <li
                              className="dropdown"
                              key={list.main_category_id}
                            >
                              <Link to={`/category/${list.main_category_name}`}>
                                {list.main_category_name}
                              </Link>
                              <ul>
                                {sctg
                                  .filter(
                                    (f) =>
                                      f.main_category_id ===
                                      list.main_category_id
                                  )
                                  .map((s) => (
                                    <li>
                                      <Link
                                        to={`/category/${list.main_category_name}/${s.sub_category_name}`}
                                      >
                                        {s.sub_category_name}
                                      </Link>
                                    </li>
                                  ))}
                              </ul>
                            </li>
                          ))}
                        </ul>
                      </li>
                      <li>
                        <a href="/contact-us">Contact Us</a>
                      </li>
                      <li>
                        <a href="/more-details">More</a>
                      </li>
                    </ul>
                  </div>
                </nav>
              </div>
              <div className="menu-right-content clearfix pull-right">
                <ul className="social-style-one clearfix float-right d-lg-block ">
                  <li>
                    <a href={`${list.facebook}`} target="_blank">
                      <i className="fab fa-facebook-f" />
                    </a>
                  </li>
                  <li>
                    <a href={`${list.instagram}`} target="_blank">
                      <i className="fab fa-instagram" />
                    </a>
                  </li>
                  <li>
                    <a href={`${list.linkedin}`} target="_blank">
                      <i className="fab fa-linkedin-in" />
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>

        <div className="sticky-header">
          <div className="auto-container">
            <div className="outer-box clearfix">
              <div className="menu-area pull-left">
                <nav className="main-menu navbar-expand-md navbar-light">
                  <div
                    className="collapse navbar-collapse show clearfix"
                    id="navbarSupportedContent"
                  >
                    <ul className="navigation clearfix">
                      <li className="">
                        <a href="/">Home</a>
                      </li>
                      <li className="current">
                        <a href="/about-us">About Us</a>
                      </li>
                      <li className="dropdown">
                        <a href="#">Products</a>
                        <ul>
                          {mctg.map((list) => (
                            <li
                              className="dropdown"
                              key={list.main_category_id}
                            >
                              <Link to={`/category/${list.main_category_name}`}>
                                {list.main_category_name}
                              </Link>
                              <ul>
                                {sctg
                                  .filter(
                                    (f) =>
                                      f.main_category_id ===
                                      list.main_category_id
                                  )
                                  .map((s) => (
                                    <li>
                                      <Link
                                        to={`/category/${list.main_category_name}/${s.sub_category_name}`}
                                      >
                                        {s.sub_category_name}
                                      </Link>
                                    </li>
                                  ))}
                              </ul>
                            </li>
                          ))}
                        </ul>
                      </li>
                      <li>
                        <a href="/contact-us">Contact Us</a>
                      </li>
                      <li>
                        <a href="/more-details">More</a>
                      </li>
                    </ul>
                  </div>
                </nav>
              </div>
              <div className="menu-right-content clearfix pull-right">
                <ul className="social-style-one clearfix">
                  <li>
                    <a href={`${list.facebook}`} target="_blank">
                      <i className="fab fa-facebook-f" />
                    </a>
                  </li>
                  <li>
                    <a href={`${list.instagram}`} target="_blank">
                      <i className="fab fa-instagram" />
                    </a>
                  </li>
                  <li>
                    <a href={`${list.linkedin}`} target="_blank">
                      <i className="fab fa-linkedin-in" />
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </header>

      <div className="mobile-menu">
        <div className="menu-backdrop" />
        <div className="close-btn">
          <i className="fas fa-times" />
        </div>
        <nav className="menu-box">
          <div className="nav-logo">
            <a href="/">
              <img
                src="/assets/images/imon-logo.png"
                width="150px"
                alt="logo"
                title
              />
            </a>
          </div>

          <div className="contact-info">
            <h4>Contact Info</h4>
            <ul>
              <li>{list.location}</li>
              <li>
                <a href={`tel:+${list.num1}`}>+91{list.num1}</a>
              </li>
              <li>
                <a href={`mailto:${list.email1}`}>{list.email1}</a>
              </li>
            </ul>
          </div>
          <div className="social-links">
            <ul className="clearfix">
              <li>
                <a href={`${list.facebook}`}>
                  <i className="fab fa-facebook-f" />
                </a>
              </li>
              <li>
                <a href={`${list.instagram}`}>
                  <i className="fab fa-instagram" />
                </a>
              </li>
              <li>
                <a href={`${list.linkedin}`}>
                  <i className="fab fa-linkedin-in" />
                </a>
              </li>
            </ul>
          </div>
        </nav>
      </div>
      <section
        className="page-title"
        style={{
          backgroundImage: "url(/assets/images/background/swing-1.webp)",
        }}
      >
        <div className="auto-container">
          <div className="content-box">
            <div className="title">
              <h1>About Company</h1>
            </div>
            <ul className="bread-crumb clearfix">
              <li>
                <a href="/">Home</a>
              </li>
              <li>About</li>
              <li>Company</li>
            </ul>
          </div>
        </div>
      </section>

      <section className="about-style-five sec-pad bg-color-1 pb-2">
        <div className="auto-container">
          <div className="row clearfix">
            <div className="col-lg-6 col-md-12 col-sm-12 image-column">
              <div className="image_block_3">
                <div className="image-box">
                  <figure className="logo-icon mt-5">
                    <img src="assets/images/icons/logo.png" alt="logo" />
                  </figure>
                  <div className="row clearfix">
                    <div className="col-lg-6 col-md-6 col-sm-12 column">
                      <figure
                        className="image wow fadeInUp animated animated"
                        data-wow-delay="00ms"
                        data-wow-duration="1500ms"
                      >
                        <img
                          src="assets/images/resource/sl.jpg"
                          alt="slide-gates"
                        />
                      </figure>
                    </div>
                    <div className="col-lg-6 col-md-6 col-sm-12 column">
                      <figure
                        className="image wow fadeInUp animated animated"
                        data-wow-delay="300ms"
                        data-wow-duration="1500ms"
                      >
                        <img
                          src="assets/images/resource/sw.jpg"
                          alt="automatic-barrier"
                        />
                      </figure>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-md-12 col-sm-12 content-column">
              <div className="content_block_10">
                <div className="content-box">
                  <div className="sec-title">
                    <div className="top-title">
                      <div className="shape">
                        <div className="line line-1" />
                        <div className="line line-2" />
                        <div className="line line-3" />
                        <div className="line line-4" />
                        <div
                          className="bg-shape"
                          style={{
                            backgroundImage:
                              "url(/assets/images/icons/bg-shape-5.png)",
                          }}
                        />
                      </div>
                      <p>About Company</p>
                    </div>
                    <h2>{adata.com_name}</h2>
                  </div>

                  <ul className="list clearfix pt-4">
                    <li>
                      {" "}
                      <p>{adata.description}</p>
                    </li>
                    <li>
                      {" "}
                      <p>{adata.other_des}</p>
                    </li>
                  </ul>
                
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="faq-section sec-pad bg-color-1 mb-5 " id="accordion">
     
        {/* <div className="bg-layer" /> */}
        <div className="auto-container ">
        <div className="sec-title">
                    <div className="top-title">
                      <div className="shape">
                        <div className="line line-1" />
                        <div className="line line-2" />
                        <div className="line line-3" />
                        <div className="line line-4" />
                       
                      </div>
                      <h2>Our Core Values</h2>
                    </div>
                  </div>
          <div className="row clearfix">
            <div className="col-lg-6 col-md-12 col-sm-12 content-column">
              <div className="content_block_6">
                <div className="content-box">
                  <ul className="accordion-box">
                    <li className="accordion block">
                      <div className="acc-btn">
                        <h5
                          data-toggle="collapse"
                          data-target="#collapseOne"
                          aria-expanded="true"
                          aria-controls="collapseOne"
                        >
                          01. Customer Satisfaction
                        </h5>
                      </div>
                      <div
                        className="acc-content current"
                        id="collapseOne"
                        class="collapse show"
                        aria-labelledby="headingOne"
                        data-parent="#accordion"
                      >
                        <p style={{ padding: "23px 20px 22px 30px" }}>
                          {" "}
                          Focus on providing high-quality products and services
                          that meet the specific needs of customers. Ensure that
                          customers are satisfied with their purchase and
                          experience with the company.
                        </p>
                      </div>
                    </li>
                    <li className="accordion block">
                      <div className="acc-btn">
                        <h5
                          data-toggle="collapse"
                          data-target="#collapseTwo"
                          aria-expanded="false"
                          aria-controls="collapseTwo"
                        >
                          02. Innovation
                        </h5>
                      </div>
                      <div
                        className="acc-content"
                        id="collapseTwo"
                        class="collapse"
                        aria-labelledby="headingTwo"
                        data-parent="#accordion"
                      >
                        <p style={{ padding: "23px 20px 22px 30px" }}>
                          Embrace new technologies and business models to stay
                          ahead of the competition and provide innovative
                          solutions to customers.
                        </p>
                      </div>
                    </li>
                    <li className="accordion block">
                      <div className="acc-btn">
                        <h5
                          data-toggle="collapse"
                          data-target="#collapseThree"
                          aria-expanded="false"
                          aria-controls="collapseThree"
                        >
                          03. Integrity
                        </h5>
                      </div>
                      <div
                        className="acc-content"
                        id="collapseThree"
                        class="collapse"
                        aria-labelledby="headingThree"
                        data-parent="#accordion"
                      >
                        <p style={{ padding: "23px 20px 22px 30px" }}>
                          Conduct business with honesty, transparency, and
                          ethical standards. Build trust with customers,
                          partners, and employees by demonstrating integrity in
                          all business dealings.
                        </p>
                      </div>
                    </li>
                    <li className="accordion block">
                      <div className="acc-btn">
                        <h5
                          data-toggle="collapse"
                          data-target="#collapseFour"
                          aria-expanded="false"
                          aria-controls="collapseFour"
                        >
                          04. Collaboration
                        </h5>
                      </div>
                      <div
                        className="acc-content"
                        id="collapseFour"
                        class="collapse"
                        aria-labelledby="headingFour"
                        data-parent="#accordion"
                      >
                        <p style={{ padding: "23px 20px 22px 30px" }}>
                          Foster a culture of collaboration and teamwork among
                          employees and partners. Encourage open communication
                          and mutual respect to achieve common goals.
                        </p>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>

            <div className="col-lg-6 col-md-12 col-sm-12 content-column">
              <div className="content_block_6">
                <div className="content-box">
                  <ul className="accordion-box">
                    <li className="accordion block">
                      <div className="acc-btn">
                        <h5
                          data-toggle="collapse"
                          data-target="#collapseFive"
                          aria-expanded="false"
                          aria-controls="collapseFive"
                        >
                          05. Continuous Improvement
                        </h5>
                      </div>
                      <div
                        className="acc-content"
                        id="collapseFive"
                        class="collapse"
                        aria-labelledby="headingFive"
                        data-parent="#accordion"
                      >
                        <p style={{ padding: "23px 20px 22px 30px" }}>
                          Strive for continuous improvement in all aspects of
                          the business, including product quality, customer
                          service, and operational efficiency. Encourage
                          feedback and use data to drive decision-making.
                        </p>
                      </div>
                    </li>
                    <li className="accordion block">
                      <div className="acc-btn">
                        <h5
                          data-toggle="collapse"
                          data-target="#collapseSix"
                          aria-expanded="false"
                          aria-controls="collapseSix"
                        >
                          06. Local Expertise
                        </h5>
                      </div>
                      <div
                        className="acc-content"
                        id="collapseSix"
                        class="collapse"
                        aria-labelledby="headingSix"
                        data-parent="#accordion"
                      >
                        <p style={{ padding: "23px 20px 22px 30px" }}>
                          Develop a deep understanding of the local market and
                          customers in India. Leverage local partnerships and
                          resources to provide tailored solutions that meet the
                          unique needs of Indian customers.
                        </p>
                      </div>
                    </li>
                    <li className="accordion block">
                      <div className="acc-btn">
                        <h5
                          data-toggle="collapse"
                          data-target="#collapseSeven"
                          aria-expanded="false"
                          aria-controls="collapseSeven"
                        >
                          07. Sustainability
                        </h5>
                      </div>
                      <div
                        className="acc-content"
                        id="collapseSeven"
                        class="collapse"
                        aria-labelledby="headingSeven"
                        data-parent="#accordion"
                      >
                        <p style={{ padding: "23px 20px 22px 30px" }}>
                          Embrace sustainable business practices that minimize
                          the environmental impact of the business and
                          contribute to the well-being of local communities.
                        </p>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section class="video-section mb-5">
        <div class="auto-container">
          <div class="inner-container">
            <div
              class="video-inner"
              style={{
                backgroundImage: "url(/assets/images/background/sliding-1.jpg)",
              }}
            >
              <div class="video-btn">
                <a
                  href={`${adata.url_video}`}
                  class="lightbox-image"
                  data-fancybox="gallery"
                >
                  <i class="fas fa-play"></i>
                  <span class="border-animation border-1"></span>
                  <span class="border-animation border-2"></span>
                  <span class="border-animation border-3"></span>
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="work-section bg-color-1">
        
        {/* <figure className="image-layer"><img src="assets/images/resource/work-1.png" alt /></figure> */}
        <div className="auto-container">
        <div className="sec-title">
                    <div className="top-title">
                      <div className="shape">
                        <div className="line line-1" />
                        <div className="line line-2" />
                        <div className="line line-3" />
                        <div className="line line-4" />
                        <div
                          className="bg-shape"
                          style={{
                            backgroundImage:
                              "url(assets/images/icons/bg-shape.png)",
                          }}
                        />
                      </div>
                      <p>OUR PRESENCES</p>
                    </div>
                    <h2>Securing Entrances Across Western India</h2>
                  </div>
          <div className="row clearfix">
            <div className="col-lg-6 col-md-12 col-sm-12 image-column">
              <div className="image_block_2">
                <div className="image-box">
                  <figure className="image wow slideInLeft animated">
                    <img src="assets/images/icons/indian-map.png" alt />
                  </figure>
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-md-12 col-sm-12 content-column">
              <div className="content_block_2">
                <div className="content-box">
                 
                  <div className="inner-box">
                    <div className="single-item">
                      <h4 style={{ color: "#009b00" }}>Gujarat</h4>
                      <p>
                      Entrance automation services that cover Gujarat.
                      </p>
                    </div>
                    <div className="single-item">
                      <h4 style={{ color: "#009b00" }}>Rajasthan</h4>
                      <p>Entrance automation services that cover Rajasthan.</p>
                    </div>
                    <div className="single-item">
                      <h4 style={{ color: "#009b00" }}>Maharashtra</h4>
                      <p>Entrance automation services that cover Maharashtra.</p>
                    </div>
                    <div className="single-item">
                      <h4 style={{ color: "#009b00" }}>Madhya Pradesh</h4>
                      <p  ref={ref}>Entrance automation services that cover Madhya Pradesh.</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* <section className="team-style-two alternat-2 sec-pad">
        <div className="auto-container">
          <div className="sec-title centred">
            <div className="top-title">
              <div className="shape">
                <div className="line line-1" />
                <div className="line line-2" />
                <div className="line line-3" />
                <div className="line line-4" />
                <div
                  className="bg-shape"
                  style={{
                    backgroundImage: "url(assets/images/icons/bg-shape.png)",
                  }}
                />
              </div>
              <p>Team Memmbers</p>
            </div>
            <h2>Meet our professional team</h2>
          </div>
          <div className="row clearfix">
            {tdata
              .filter((d) => d.status !== "Inactive")
              .map((list) => (
                <div
                  className="col-lg-4 col-md-6 col-sm-12 team-block"
                  key={list.team_id}
                >
                  <div className="team-block-two">
                    <div className="inner-box">
                      <figure className="image-box">
                        <img
                          src={`https://imontechnologies.in/teamImgs/${list.img}`}
                          alt={`${list.img}`}
                        />
                      </figure>
                      <div className="content-box">
                        <span className="designation">{list.position}</span>
                        <h4 ref={ref}>
                          <a>{list.name}</a>
                        </h4>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
          </div>
        </div>
      </section> */}
      <Dealer />
      <FooterPage />
    </div>
  );
}
