import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

export default function FooterPage() {
  const [mctg, setMctg] = useState([]);
  const [list, setList] = useState([]);

  const d = new Date();
  let year = d.getFullYear();

  useEffect(() => {
    allCtg();
    ProfileList();
  }, []);

  // function topFunction() {
  //   window.scrollTo({ top: 0, behavior: "smooth" });
  // }

  const allCtg = () => {
    axios
      .get("https://imontechnologies.in/apps/MainCategory/all.php")
      .then((res) => {
        // console.log(res.data.mctglist.mctgdata);
        setMctg(res.data.mctglist.mctgdata);
      });
  };

  const ProfileList = () => {
    axios
      .get(`https://imontechnologies.in/apps/Profile/all.php`)
      .then((res) => {
        // console.log(res.data.profilelist.profiledata);
        setList(res.data.profilelist.profiledata[0]);
      });
  };

  return (
    <>
      <footer className="main-footer">
        <div className="footer-top">
          <div
            className="bg-layer"
            style={{
              backgroundImage: "url(/assets/images/background/faq-bg.jpg)",
            }}
          />
          <div className="auto-container">
            <div className="widget-section">
              <div className="row clearfix">
                <div className="col-lg-4 col-md-4 col-sm-12 footer-column">
                  <div className="footer-widget logo-widget">
                    <figure className="footer-logo">
                      <a href="/">
                        <img
                          src="/assets/images/imon-logo.png"
                          alt="logo"
                          style={{ width: 150 }}
                        />
                      </a>
                    </figure>
                    <div className="support-box">
                      <h4>
                        <a href={`tel:${list.num1}`}>+91{list.num1}</a>
                      </h4>
                      <p>Call for Dealership Inquiry!</p>
                    </div>
                    <div className="text">
                      <p>
                        Right to find fault with a man who chooses too enjoy a
                        pleasures that annoying consequences.
                      </p>
                      <a href="/about-us">
                        Read more
                        <i className="fas fa-angle-right" />
                      </a>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4 col-md-4 col-sm-12 footer-column">
                  <div className="footer-widget links-widget">
                    <div className="widget-title">
                      <h3>Useful Links</h3>
                    </div>
                    <div className="widget-content clearfix">
                      <ul className="links-list clearfix">
                        <li>
                          <a href="/about-us">About Company</a>
                        </li>
                        {mctg.map((list) => (
                          <li key={list.main_category_id}>
                            <Link to={`/category/${list.main_category_name}`}>
                              {list.main_category_name}
                            </Link>
                          </li>
                        ))}
                        <li>
                          <a href="/contact-us">Contact Us</a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4 col-md-4 col-sm-12 footer-column">
                  <div className="footer-widget post-widget">
                    <div className="widget-title">
                      <h3>Popular Products</h3>
                    </div>
                    <div className="post-inner">
                      {mctg.map((list) => (
                        <div className="post">
                          <figure className="post-thumb">
                            <img
                              src={`/mainctgImgs/${list.main_category_img}`}
                              alt={list.main_category_img}
                              style={{ height: "25px" }}
                            />
                          </figure>
                          <h5>
                            <a href={`/category/${list.main_category_name}`}>
                              {list.main_category_name}
                            </a>
                          </h5>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="footer-info">
              <ul className="info-list clearfix">
                <li>
                  <i className="icon-location" />
                  <h6>Location</h6>
                  <p>{list.location}</p>
                </li>
                <li>
                  <i className="icon-email-1" />
                  <h6>Mail us</h6>
                  <p>
                    <a href={`mailto:${list.email1}`}>{list.email1}</a>
                  </p>
                </li>
                <li>
                  <i className="icon-clock" />
                  <h6>Office hrs</h6>
                  <p>{list.hrs}</p>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div className="footer-bottom">
          <div className="auto-container">
            <div className="bottom-inner clearfix">
              <div className="copyright pull-left">
                <p>
                  © {year} <a href="/"> Imon Technologies Pvt Ltd</a>, All
                  Rights Reserved.
                </p>
              </div>
              <ul className="footer-nav pull-right clearfix">
                <li>
                  <a href="/">Privacy Policy</a>
                </li>
                <li>
                  <a href="/">Terms &amp; Conditions</a>
                </li>
                <li>
                  <a href="/">Site Map</a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </footer>
      <a
        aria-label="Chat on WhatsApp"
        href="https://wa.me/919909997209"
        target="_blank"
        className="scroll-top scroll-to-target"
        data-target="html"
      >
        <img src="/assets/images/banner/WhatsApp.png" />
      </a>
    </>
  );
}
