import "./App.css";
import Categories from "./Components/Categories/Categories";
import ProductDetails from "./Components/Categories/ProductDetails";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import HomePage from "./Pages/HomePage";
import AboutPage from "./Components/Categories/AboutPage";
import Contact from "./Components/Categories/Contact";
import SubCategories from "./Components/Categories/SubCategories";
import VideoPage from "./Pages/VideoPage";

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route path={"/"} element={<HomePage />} />
          <Route path={"/about-us"} element={<AboutPage />} />
          <Route path={"/more-details"} element={<VideoPage />} />
          <Route path={"/contact-us"} element={<Contact />} />
          <Route path={"/category/:names/"} element={<Categories />} />
          <Route
            path={"/category/:names/:snames"}
            element={<SubCategories />}
          />
          <Route
            path={"/category/:names/:snames/product/:product"}
            element={<ProductDetails />}
          />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
